export const textInputStyles = (theme) => ({
  default: {
    alignItems: 'stretch',
    appearance: 'none',
    backgroundColor: 'transparent',
    borderColor: theme.color.neutral.black,
    borderRadius: 0,
    borderStyle: 'solid',
    borderWidth: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    flex: 1,
    font: 'inherit',
    margin: 0,
    minWidth: 0,
    padding: 4,
    position: 'relative',
    resize: 'none',
    zIndex: 0,
  },
  error: {
    color: theme.color.messages.error,
  }
});
