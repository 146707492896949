import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

const valueToCSS = (value = undefined, unit = 'px') => {
  if (!value) {
    return;
  }

  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
      return `${value}${unit}`;
    default:
      throw new Error('invalid css entry');
  }
}

/**
 * The only true button.
 */
export class Button extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Button';

  static propTypes = {
    children: PropTypes.node,
    /** alias for css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** Disabled button. */
    disabled: PropTypes.bool,
    /** Secondary styling for the button. */
    secondary: PropTypes.bool,
    /** Light styling for the button. */
    light: PropTypes.bool,
    /** Pressed styling for the button. */
    pressed: PropTypes.bool,
    /** Larger padding for the button. */
    large: PropTypes.bool,
    /** Smaller padding and font for the button. */
    small: PropTypes.bool,
    /** Height of the button */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Width of the button */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** type of the button */
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    /** click event hadnler */
    onClick: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    secondary: false,
    light: false,
    large: false,
    small: false,
    pressed: false,
    height: null,
    width: null,
    type: 'button',
    onClick: () => {},
  }

  render() {
    const {
      children,
      classes,
      css: cssOverrides,
      disabled,
      height,
      pressed,
      large,
      light,
      secondary,
      small,
      width,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;
    // in order of priority (|| operator will short circuit)
    const pressedTheme = (disabled && theme.button.disabled.pressed) ||
      (light && theme.button.light.pressed) ||
      (secondary && theme.button.secondary.pressed) ||
      theme.button.primary.pressed;

    return (
      <button
        css={css(
          theme.button.primary,
          secondary && theme.button.secondary,
          light && theme.button.light,
          disabled && theme.button.disabled,
          pressed && pressedTheme,
          small && theme.button.small,
          large && theme.button.large,
          {
            width: valueToCSS(width),
            height: valueToCSS(height),
          },
          classes,
          cssOverrides,
        )}
        {...other}
      >
        {children}
      </button>
    );
  }
}

export default Button;
