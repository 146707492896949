import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MaterialSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

export class Select extends Component {
  static displayName = 'Select';

    static propTypes = {
      children: PropTypes.node,
      /** Callback function fired when a menu item is selected. Signature function(event: object, child?: object) => void */
      onChange: PropTypes.func,
      /** Callback fired when the component requests to be closed. Use in controlled mode (see open). Signature: function(event: object) => void */
      onClose: PropTypes.func,
      /** Callback fired when the component requests to be opened. Use in controlled mode (see open). Signature: function(event: object) => void */
      onOpen: PropTypes.func,
      /** any style overrides */
      css: PropTypes.object,
      /** Alias for CSS */
      classes: PropTypes.object,
      /** Forwarded ref to the underlying native input element */
      inputRef: PropTypes.object,
      /** Custom render the selected value. Signature: function(value: any) => ReactElement */
      renderValue: PropTypes.func,
  };

  render() {
    const { classes, css: cssOverrides, ...other } = this.props;

    const combinedCss = merge({}, classes, cssOverrides);
    const StyledSelect = withStyles(combinedCss)(MaterialSelect);
    return <StyledSelect {...other} />;
  }

}

export default Select;
