/** @jsx jsx */
import _ from 'lodash';
import { Component } from 'react';
import { css, jsx } from '@emotion/core';

import defaultTheme from '../../styles/theme';

export class TableCell extends Component {
  static displayName = 'TableCell';

  render() {
    const {
      children,
      classes = {},
      css: cssOverrides = {},
      ...other
    } = this.props;
    const { theme = defaultTheme } = this.context;

    const finalStyles = _.merge({},
      theme.table.cell,
      classes,
      cssOverrides,
    );

    return (
      <td css={css(finalStyles)} {...other}>
        {children}
      </td>
    )
  }
}

export default TableCell;
