import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css } from '@emotion/core';
import View from '../view/view.component';
import { merge } from 'lodash'

import defaultTheme from '../../styles/theme';

export class NavigationTab extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'NavigationTab';

  static propTypes = {
    css: PropTypes.object,
    classes: PropTypes.object,
    children: PropTypes.node,
    active: PropTypes.bool,
  }

  static defaultProps = {
    active: false,
    classes: {},
    css: {},
  }

  render() {
    const {
      active,
      children,
      classes: classesProp,
      css: cssOverrides = {},
      ...rest
    } = this.props;
    const { theme = defaultTheme } = this.context;
    const mergedCss = merge({}, classesProp, cssOverrides);
    const classes = merge({}, theme.navigation.tab, mergedCss);
    return (
      <View
        className={classNames({ active })}
        classes={css(
          classes.default,
          active && classes.active,
        )}
        {...rest}
      >
        {children}
      </View>
    )
  }
}

export default NavigationTab;
