import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import View from '../view/view.component';
import CardHeader from './card-header.component';

import defaultTheme from '../../styles/theme';

/**
 * The only true card.
 */
export class Card extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Card';

  static propTypes = {
    children: PropTypes.node,
    /** If `true`, the card will use raised styling. */
    raised: PropTypes.bool,
    /** If `true`, the card will have rounded corners. */
    rounded: PropTypes.bool,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Any CSS overrides */
    css: PropTypes.object,
    /** If provided, will automatically render out a CardHeader. Do not include CardHeader if including this prop  */
    title: PropTypes.string,
  };

  static defaultProps = {
    raised: true,
    classes: {},
    css: {},
    title: null,
  };

  render() {
    const { theme = defaultTheme } = this.context;

    const {
      children,
      classes = {},
      css: cssOverrides = {},
      raised,
      rounded,
      title,
      ...other
    } = this.props;

    return (
      <View
        css={
          css(
            theme.card.root,
            raised && theme.card.raised,
            rounded && theme.card.rounded,
            classes.root,
            cssOverrides.root,
          )
        }
        {...other}
      >
        {title && <CardHeader title={title} />}
        {children}
      </View>
    );
  }
}

export default Card;
