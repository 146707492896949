const carrotIconWidth = 30;

export const selectNativeStyles = (theme) => ({
  root: {},
  nativeWrapper: {},
  native: {
    display: 'inline-block',
    /* White */
    background: theme.color.neutral.white,
    /* Neutral5 */
    color: theme.color.neutral.black,
    border: `1px solid ${theme.color.neutral.neutral5}`,
    borderRight: `0px solid transparent`,
    boxSizing: 'content-box',
    borderRadius: theme.spacing.sp0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    margin: 1,
    marginRight: 4,
    padding: theme.spacing.sp2,
    paddingRight: (theme.spacing.sp2 + (carrotIconWidth)), // make room for the down arrow and error symbol
    appearance: 'none',
    cursor: 'pointer',
    ...theme.typography.variants.body,
    '> option': {
      ...theme.typography.variants.body,
    },
    '&:focus': {
      margin: 0,
      borderWidth: 2,
      borderRightWidth: 0,
      borderColor: theme.color.primary.primary5,
      outline: 'none',
    },
  },
  nativeError: {
    margin: 0,
    borderWidth: 2,
    borderRightWidth: 0,
    borderColor: theme.color.messages.error,
  },
  nativeDisabled: {
    backgroundColor: theme.color.neutral.neutral3,
    color: theme.color.neutral.neutral7,
    cursor: 'not-allowed',
    opacity: 1, // clear out user-agent-styles
  },
  nativePristine: {
    fontStyle: 'italic',
    color: theme.color.neutral.neutral7,
  },
  icon: {
    display: 'inline-block',
    position: 'absolute',
    right: 0,
    margin: 1,
    border: `1px solid ${theme.color.neutral.neutral5}`,
    borderLeft: `0px solid transparent`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing.sp0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: theme.spacing.sp2,
    pointerEvents: 'none',
    'select:focus + &': {
      margin: 0,
      borderWidth: 2,
      borderLeftWidth: 0,
      borderColor: theme.color.primary.primary5,
    },
  },
  iconError: {
    margin: 0,
    borderWidth: 2,
    borderLeftWidth: 0,
    borderColor: theme.color.messages.error,
    'select:focus + &': {
      borderColor: theme.color.primary.primary5,
    },
  },
  iconDisabled: {
    backgroundColor: theme.color.neutral.neutral3,
  },
  errorIcon: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%) translateX(-30px)',
    color: theme.color.messages.error,
    pointerEvents: 'none',
  },
  placeholder: {
    ...theme.typography.variants.body,
    fontStyle: 'itallic',
    color: theme.color.neutral.neutral5,
    pointerEvents: 'none',
    height: '1.5rem',
  },
  label: {},
  labelError: {
    color: theme.color.messages.error,
  },
  helpText: {
    marginTop: 0,
    marginBottom: theme.spacing.sp1,
    color: theme.color.neutral.neutral7,
  },
  helpTextError: {
    color: theme.color.messages.error,
  },
});

export default selectNativeStyles;
