import { merge } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';
import List from '../list/list.component';
import SearchMenuItem from './search-menu-item.component';

export default class SearchMenu extends Component {
  static displayName = 'SearchMenu';

  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    class: PropTypes.object,
    error: PropTypes.object,
    getItemProps: PropTypes.func.isRequired,
    highlightedIndex: PropTypes.number,
    isEmpty: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
  };

  static defaultProps = {
    highlightedIndex: 0,
    isEmpty: false,
    results: [],
  }

  renderChildren() {
    const {
      children,
      getItemProps,
      highlightedIndex,
      results,
    } = this.props;

    if (children) {
      return children;
    }

    if (results && results.length > 0) {
      return results.map((item, index) => (
        <SearchMenuItem
          item={item}
          isHighlighted={highlightedIndex === index}
          {...getItemProps({ item, key: item.id })}
        />
      ));
    }
  }

  render() {
    const { classes, css: cssOverrides = {} } = this.props;
    const { theme = defaultTheme } = this.context;

    return (
      <List css={css(theme.search.results, merge({}, classes, cssOverrides))}>
        { this.renderChildren() }
      </List>
    );
  }
}
