import { merge } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import View from '../view/view.component';
import Text from '../text/text.component';

import defaultTheme from '../../styles/theme';

export class CardHeader extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'CardHeader';

  static propTypes = {
    children: PropTypes.node,
    /** Use one of the predefined header styles */
    headerStyle: PropTypes.oneOf(Object.keys(defaultTheme.card.header.variants)),
    classes: PropTypes.object,
    css: PropTypes.object,
  };

  static defaultProps = {
    classes: {},
    css: {},
  };

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      children,
      title,
      subtitle,
      headerStyle,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    let titleComponent;

    const combinedCss = merge({}, classes, cssOverrides);

    if (title) {
      titleComponent = typeof title === 'string' ? (
        <Text
          css={css(
            theme.card.header.title,
            combinedCss.title,
          )}
        >
          {title}
        </Text>
      ) : title;
    }

    return (
      <View
        css={css(
          theme.card.header.root,
          theme.card.header.variants[(headerStyle || 'border')],
          combinedCss.root,
          combinedCss,
        )}
        row
        {...other}
      >
        {titleComponent}
        {children}
      </View>
    );
  }
}

export default CardHeader;
