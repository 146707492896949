const TIMELINE_STYLE_SETTINGS = {
  background: 'white',
  primaryColor: '#777',
  lineThickness: 1,
  timelineDotBtmMargin: 10,
  timelineDotDiameter: 20,
};

export default {
  timeline: {
    margin: '0 33px',
  },
  timelineInnerStyles: {
    display: 'flex',
    flexDirection: 'column',
    '@media(min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  // TimelineNode Content
  circle: {
    backgroundColor: TIMELINE_STYLE_SETTINGS.background,
    border: `2px solid ${TIMELINE_STYLE_SETTINGS.primaryColor}`,
    borderRadius: TIMELINE_STYLE_SETTINGS.timelineDotDiameter,
    boxSizing: 'border-box',
    display: 'block',
    height: TIMELINE_STYLE_SETTINGS.timelineDotDiameter,
    left: 0,
    position: 'absolute',
    transform: 'none',
    width: TIMELINE_STYLE_SETTINGS.timelineDotDiameter,
    zIndex: 2,
    marginLeft: 0,
    '@media(min-width: 768px)': {
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%) translateY(0)',
    },
  },

  content: {
    textAlign: 'left',
    marginLeft: `${TIMELINE_STYLE_SETTINGS.timelineDotDiameter + 10}px`,

    '@media(min-width: 768px)': {
      textAlign: 'center',
      marginLeft: 0
    }
  },

  timelineNodeStyles: {
    flexBasis: '100%',
    flexShrink: 1,
    paddingTop: `${(TIMELINE_STYLE_SETTINGS.timelineDotBtmMargin + TIMELINE_STYLE_SETTINGS.timelineDotDiameter)}px`,
    position: 'relative',
    textAlign: 'center',
    zIndex: 'initial',


    '&:first-of-type, &:last-of-type': {
      flexBasis: '50%',
    },

    // Line
    ':after': {
      border: `${TIMELINE_STYLE_SETTINGS.lineThickness}px solid ${TIMELINE_STYLE_SETTINGS.primaryColor}`,
      bottom: 0,
      content: '""',
      display: 'block',
      left: `${(TIMELINE_STYLE_SETTINGS.timelineDotDiameter / 2) - TIMELINE_STYLE_SETTINGS.lineThickness}px`,
      position: 'absolute',
      top: 0,
      transform: 'none',
      width: 0,
      zIndex: 1,
    },

    '&:first-of-type::after': {
      left: '0px',

      '@media(max-width: 768px)': {
        border: '0px none',
      },
    },

    '@media(min-width: 768px)': {
      paddingBottom: `${(TIMELINE_STYLE_SETTINGS.timelineDotBtmMargin + TIMELINE_STYLE_SETTINGS.timelineDotDiameter)}px`,
      paddingTop: 0,

      '&:first-of-type .timeline-node-inner-content': {
        textAlign: 'left',
      },

      '&:last-child .timeline-node-inner-content': {
        textAlign: 'right',
      },

      // Line
      ':after': {
        bottom: `${TIMELINE_STYLE_SETTINGS.timelineDotDiameter / 2}px`,
        left: '-50%',
        right: 5,
        top: 'auto',
        transform: `translateY(${TIMELINE_STYLE_SETTINGS.lineThickness}px)`,
        width: '100%',
      },
      '&:first-of-type .timeline-node-inner-circle': {
        transform: 'none',
        left: 0,
      },
      '&:last-of-type .timeline-node-inner-circle': {
        transform: 'none',
        left: 'auto',
        right: 0,
      },
      '&:last-child::after': {
        width: '195%',
        left: '-100%',
        transform: `translateX(1%) translateY(${TIMELINE_STYLE_SETTINGS.lineThickness}px)`,
      },
    },
  },
};
