export const pxToRem = (px) => (
  `${Math.round((px / 16) * 100) / 100}rem`
);

export const panelStyles = (theme) => ({
  header: {
    root: {
      cursor: 'pointer',
      alignItems: 'center',
      paddingBottom: theme.spacing.sp4,
      paddingTop: theme.spacing.sp4,
      paddingLeft: theme.spacing.sp4,
      paddingRight: theme.spacing.sp4,
      color: theme.color.neutral.neutral7,
      justifyContent: 'space-between',
      ":after": {
        border: 0,
      }
    },
    title: {
      flexGrow: 1,
      // width: '100%',
      ...theme.typography.h4,
    },
  },
  content: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    paddingTop: theme.spacing.sp4,
    paddingBottom: theme.spacing.sp4,
    paddingLeft: theme.spacing.sp4,
    paddingRight: theme.spacing.sp4,
    color: theme.color.neutral.neutral6,
  },
  icon: {
    ...theme.typography.variants.h3,
    cursor: 'pointer',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#6d6d6d',
  },
  iconExpanded: {
    transform: 'rotate(180deg)',
  },
  root: {
    padding: 0,
    overflow: 'hidden',
    background: theme.color.neutral.white,
    borderRadius: 0,
  },
  divider: {
    borderBottom: theme.component.border.light,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: theme.spacing.sp4,
    marginRight: theme.spacing.sp4,
  },
});

export default panelStyles;
