const borderWidth = 1;

export const buttonStyles = (theme) => ({
  primary: {
    ...theme.typography.variants.button,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: theme.color.primary.primary7,
    borderColor: theme.color.primary.primary7,
    borderRadius: theme.component.radius.r1,
    borderStyle: 'solid',
    borderWidth: `${borderWidth}px`,
    color: theme.color.neutral.white,
    cursor: 'pointer',
    justifyContent: 'center',
    padding: `${theme.spacing.sp2 - borderWidth}px ${theme.spacing.sp3}px`,
    outline: 'none',
    '&:focus': {
      borderColor: theme.color.neutral.white,
      boxShadow: `0 0 0 2px ${theme.color.neutral.white}, 0 0 0 4px ${theme.color.primary.primary7}`,
    },
    '&:hover': {
      backgroundColor: theme.color.primary.primary6,
      borderColor: theme.color.primary.primary6,
    },
    '&:active': {
      backgroundColor: theme.color.primary.primary8,
      borderColor: theme.color.primary.primary8,
    },
    pressed: {
      backgroundColor: theme.color.primary.primary8,
      borderColor: theme.color.primary.primary8,
      '&:hover': {
        backgroundColor: theme.color.primary.primary6,
        borderColor: theme.color.primary.primary6,
      },
      '&:active': {
        backgroundColor: theme.color.primary.primary8,
        borderColor: theme.color.primary.primary8,
      },
    },
  },
  large: {
    padding: `${theme.spacing.sp4 - borderWidth}px ${theme.spacing.sp5}px`,
    borderRadius: theme.component.radius.r2,
  },
  secondary: {
    backgroundColor: theme.color.neutral.white,
    borderColor: theme.color.primary.primary4,
    color: theme.color.primary.primary7,
    '&:hover': {
      backgroundColor: theme.color.primary.primary1,
      color: theme.color.primary.primary7,
      borderColor: theme.color.primary.primary4,
    },
    pressed: {
      backgroundColor: theme.color.primary.primary2,
      borderColor: theme.color.primary.primary4,
      color: theme.color.primary.primary7,
    },
    '&:active': {
      backgroundColor: theme.color.primary.primary2,
      borderColor: theme.color.primary.primary4,
      color: theme.color.primary.primary7,
    },
    '&:active:focus': {
      backgroundColor: theme.color.primary.primary2,
      borderColor: theme.color.primary.primary4,
      color: theme.color.primary.primary7,
    },
    '&:focus': {
      backgroundColor: theme.color.neutral.white,
      borderColor: theme.color.primary.primary4,
      boxShadow: `0 0 0 2px ${theme.color.neutral.white}, 0 0 0 4px ${theme.color.primary.primary4}`,
    },
  },
  light: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: theme.color.primary.primary7,
    '&:hover': {
      backgroundColor: theme.color.primary.primary1,
      color: theme.color.primary.primary7,
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: theme.color.primary.primary2,
      borderColor: 'transparent',
      color: theme.color.primary.primary7,
    },
    '&:active:focus': {
      backgroundColor: theme.color.primary.primary2,
      borderColor: 'transparent',
      color: theme.color.primary.primary7,
    },
    '&:focus': {
      borderColor: 'transparent',
      boxShadow: `0 0 0 2px ${theme.color.neutral.white}, 0 0 0 4px ${theme.color.primary.primary5}`,
    },
    pressed: {
      backgroundColor: theme.color.primary.primary2,
      borderColor: 'transparent',
      color: theme.color.primary.primary7,
    },
  },
  small: {
    ...theme.typography.variants.buttonSmall,
    padding: `${theme.spacing.sp1 - borderWidth}px ${theme.spacing.sp2}px`,
  },
  disabled: {
    backgroundColor: theme.color.neutral.neutral4,
    borderColor: theme.color.neutral.neutral4,
    color: theme.color.neutral.white,
    cursor: 'not-allowed',
    '&:focus': {
      backgroundColor: theme.color.neutral.neutral4,
      borderColor: theme.color.neutral.neutral4,
      color: theme.color.neutral.white,
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: theme.color.neutral.neutral4,
      borderColor: theme.color.neutral.neutral4,
      color: theme.color.neutral.white,
    },
    '&:active': {
      backgroundColor: theme.color.neutral.neutral4,
      borderColor: theme.color.neutral.neutral4,
      color: theme.color.neutral.white,
    },
    pressed: {},
  },
});

export default buttonStyles;
