import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// TODO: make this our own ~ currently mooching off of material-ui
export class Tooltip extends Component {
  static displayName = 'Tooltip';

  static propTypes = {
    children: PropTypes.element.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    disableFocusListener: PropTypes.bool,
    disableHoverListener: PropTypes.bool,
    disableTouchListener: PropTypes.bool,
    enterDelay: PropTypes.number,
    enterTouchDelay: PropTypes.number,
    id: PropTypes.string,
    leaveDelay: PropTypes.number,
    leaveTouchDelay: PropTypes.number,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    placement: PropTypes.oneOf([
      'bottom-end',
      'bottom-start',
      'bottom',
      'left-end',
      'left-start',
      'left',
      'right-end',
      'right-start',
      'right',
      'top-end',
      'top-start',
      'top',
    ]),
    PopperProps: PropTypes.object,
    theme: PropTypes.object,
    title: PropTypes.node.isRequired,
  }

  render() {
    const { type, classes = {}, ...other } = this.props;

    const StyledTooltip = withStyles(classes)(MaterialTooltip);
    return <StyledTooltip {...other} />;
  }
}

export default Tooltip;
