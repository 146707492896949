// Based on the rules at
// https://material-ui.com/style/typography/
import * as color from './color';

export const pxToRem = (px) => (
  `${(px / 16)}rem`
);

export const families = {
  sanSerif: '"Lato", "Helvetica Neue", sans-serif',
  serif: "",
  mono: "",
}

export const variants = {
  h1: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(44),
    lineHeight: pxToRem(56),
    fontWeight: 900,
  },
  h2: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(40),
    fontWeight: 900,
  },
  h3: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: 900,
  },
  h4: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(17),
    lineHeight: pxToRem(24),
    fontWeight: 900,
  },
  h5: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: 900,
  },
  h6: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 900,
  },
  overline: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(13),
    lineHeight: pxToRem(20),
    textTransform: 'uppercase',
    fontWeight: 400,
    letterSpacing: pxToRem(1.18),
  },
  body: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
  },
  button: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 900,
  },
  buttonSmall: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: 400,
  },
  small: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: 400,
  },
  tiny: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
  caption: {
    fontFamily: families.sanSerif,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
  },
};

export const weights = {
  'light': { fontWeight: 300 }, // TODO: Consider removing, vet usage and impact on download size.
  'normal': { fontWeight: 400 },
  'bold': { fontWeight: 900 },
};

export const colors = {
  'inherit': { color: 'inherit' },
  'base': { color: color.neutral.black },
  'body': { color: color.neutral.black },
  'headings': { color: color.neutral.black },
  'light': { color: color.neutral.white },
  'dark': { color: color.neutral.neutral7 },
  'grey': { color: color.neutral.neutral7 },
  'error': { color: color.messages.error },
  'primary': { color: color.primary.primary5 },
}

export const link = {
  color: color.primary.primary7,
  textDecoration: 'underline',
  cursor: 'pointer',
};
