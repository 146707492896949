// https://github.com/facebook/css-layout#default-values
export const viewStyles = (theme) => ({
  default: {
    ...theme.typography.variants.body,
    alignItems: 'stretch',
    borderWidth: 0,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    display: 'flex',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 0,
    flexDirection: 'column',
    MsFlexDirection: 'column',
    /* fix flexbox bugs */
    minHeight: 0,
    minWidth: 0,
    flex: '0 0 auto',
  },
  row: {
    flexDirection: 'row',
    MsFlexDirection: 'row',
  },
  inline: {
    display: 'inline-flex',
  },
});

export default viewStyles;
