import Styles from '../../styles';
import { pxToRem } from '../../styles/typography';

export const notificationStyles = (theme) => ({
  root: {
    padding: `20px ${Styles.spacing.sp4}px`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-between',
  },
  'info-dark': {
    color: theme.color.neutral.white,
    background: theme.color.primary.primary9,
  },
  'info-light': {
    color: theme.color.neutral.black,
    background: theme.color.neutral.neutral3,
  },
  caution: {
    color: theme.color.neutral.black,
    background: theme.color.messages['caution-bkgd'],
  },
  error: {
    color: theme.color.neutral.white,
    background: theme.color.messages['error-bkgd'],
  },
  success: {
    color: theme.color.neutral.white,
    background: theme.color.messages['success-bkgd'],
  },
  message: {
    width: '100%',
  },
  actionButton: {
    width: 120,
    alignSelf: 'flex-end',
  },
  icon: {
    fontSize: theme.spacing.sp4,
  },
  remove: {
    cursor: 'pointer',
    flexShrink: '0',
    fontSize: pxToRem(24),
    marginLeft: theme.spacing.sp3,
  },
});

export default notificationStyles;
