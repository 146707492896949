function getCheckedIcon(fillColor) {
  return `data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="50%" cy="50%" r="6" fill="${fillColor}"/></svg>`.replace('#', '%23');
}

export const radioStyles = (theme) => ({
  input: {
    width: 18,
    height: 18,
    margin: 0,
    border: `2px solid ${theme.color.primary.primary4}`,
    appearance: 'none',
    borderRadius: '50%',
    flexShrink: 0,
    ':checked': {
      background: `url('${getCheckedIcon(theme.color.primary.primary7)}') center center no-repeat`,
      backgroundSize: 'contain',
      borderColor: theme.color.primary.primary7,
    },
    ':disabled': {
      borderColor: theme.color.neutral.neutral4,
    },
    ':disabled:hover': {
      borderColor: theme.color.neutral.neutral4,
    },
    ':checked:disabled': {
      background: `url('${getCheckedIcon(theme.color.neutral.neutral4)}') center center no-repeat`,
      backgroundSize: 'contain',
      borderColor: theme.color.neutral.neutral4,
    },
    ':checked:disabled:hover': {
      background: `url('${getCheckedIcon(theme.color.neutral.neutral4)}') center center no-repeat`,
      backgroundSize: 'contain',
      borderColor: theme.color.neutral.neutral4,
    },
    ':focus': {
      boxShadow: `0 0 0 2px ${theme.color.neutral.white}, 0 0 0 4px ${theme.color.primary.primary5}`,
      outline: 'none !important',
    },
    ':hover': {
      borderColor: theme.color.primary.primary6,
    },
    ':checked:hover': {
      borderColor: theme.color.primary.primary6,
      background: `url('${getCheckedIcon(theme.color.primary.primary6)}') center center no-repeat`,
      backgroundSize: 'contain',
    },
    ':active': {
      background: `url('${getCheckedIcon(theme.color.primary.primary8)}') center center no-repeat`,
      borderColor: theme.color.primary.primary8,
      backgroundSize: 'contain',
    },
    ':checked:active': {
      background: `url('${getCheckedIcon(theme.color.primary.primary8)}') center center no-repeat`,
      borderColor: theme.color.primary.primary8,
      backgroundSize: 'contain',
    },
    ':active:disabled': {
      background: 'none',
    },
    marginRight: '.5rem',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: theme.color.black,
    ...theme.typography.variants.body,
  },
  disabled: {
    color: theme.color.neutral.neutral4,
  },
  smallLabel: {
    ...theme.typography.variants.small,
  },
  smallInput: {
    width: 15,
    height: 15,
  }
});

export default radioStyles;
