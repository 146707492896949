import { merge } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import ReactModal from 'react-modal';
import noScroll from 'no-scroll';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

import { ModalTitle } from './modal-title.component';
import { Icon } from '../icon/icon.component';
import { Spinner } from '../spinner/spinner.component';
import { Text } from '../text/text.component';
import { View } from '../view/view.component';

import './modal.styles.css'

/**
 * The only true modal.
 */
export class Modal extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Modal';

  static propTypes = {
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /* Boolean describing if we show a close button. */
    closable: PropTypes.bool,
    /* Boolean or string indicating if we show loading state.
       If a string is passed, it will be used as the title for the loading state.
    */
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /* Boolean describing if the modal should be shown or not. */
    open: PropTypes.bool,
    /* Function that will be run after the modal has opened. */
    onAfterOpen: PropTypes.func,
    /* Function that will be run when the modal is requested to be closed
      (either by clicking on overlay or pressing ESC)
      Note: It is not called if isOpen is changed by other means. */
    onClose: PropTypes.func,
    /* Number indicating the milliseconds to wait before closing the modal. */
    closeTimeoutMS: PropTypes.number,
    /* String indicating how the content container should be announced to screenreaders */
    contentLabel: PropTypes.string,
    /* Boolean indicating if the modal should be focused after render */
    shouldFocusAfterRender: PropTypes.bool,
    /* Boolean indicating if the overlay should close the modal */
    shouldCloseOnOverlayClick: PropTypes.bool,
    /* Boolean indicating if pressing the esc key should close the modal
      Note: By disabling the esc key from closing the modal you may introduce an accessibility issue. */
    shouldCloseOnEsc: PropTypes.bool,
    /* Boolean indicating if the modal should restore focus to the element that
      had focus prior to its display. */
    shouldReturnFocusAfterClose: PropTypes.bool,
    /* Boolean indicating if the modal should allow scrolling in the background. */
    shouldScrollBackground: PropTypes.bool,
    /* String indicating the role of the modal,
      allowing the 'dialog' role to be applied if desired. */
    role: PropTypes.string,
    /* Function that will be called to get the parent element that the modal will be attached to. */
    parentSelector: PropTypes.func,
    /* Additional aria attributes (optional). */
    aria: PropTypes.object,
    /* Overlay ref callback. */
    overlayRef: PropTypes.func,
    /* Content ref callback. */
    contentRef: PropTypes.func,
    /* Boolean or string indicating if we show success state.
       If a string is passed, it will be used as the title for the success state.
    */
    success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /* Title to be displayed for modal.
      If string, it will be rendered as a default <ModalTitle/> */
    title: PropTypes.node,
  }

  static defaultProps = {
    closable: true,
    open: false,
    role: "dialog",
    ariaHideApp: false,
    closeTimeoutMS: 0,
    shouldFocusAfterRender: true,
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
    shouldReturnFocusAfterClose: true,
    shouldScrollBackground: false,
    parentSelector: () => document.body
  };

  toggleScroll = () => {
    const { open, shouldScrollBackground } = this.props;
    if (open && !shouldScrollBackground) {
      noScroll.on()
    } else {
      noScroll.off();
    }
  }

  componentDidMount() {
    this.toggleScroll();
  }

  componentDidUpdate() {
    this.toggleScroll();
  }

  componentWillUnmount() {
    // Never allow noScroll to remain on after Modals unmount
    noScroll.off();
  }

  renderTitle = (title) => {
    const { classes = {}, css: cssOverrides = {} } = this.props;

    if (!title) {
      return;
    }

    if (typeof title === 'string') {
      return (
        <ModalTitle css={css(classes.title, cssOverrides.title)}>
          {title}
        </ModalTitle>
      )
    }

    return title;
  }

  renderLoadingSuccess = (cssOverrides) => {
    const { loading: loadingClasses } = cssOverrides;

    const {
      animationName = loadingClasses.animationName,
      transitionName = loadingClasses.transitionName,
      duration = loadingClasses.duration,
      loading,
      success,
    } = this.props;
    return (
      <CSSTransition
        in={!!loading || !!success}
        timeout={duration}
        classNames={transitionName}
        unmountOnExit
      >
        <View css={css(
          loadingClasses.transition,
          loadingClasses.root,
          success && cssOverrides.success.root,
        )}>
          <View
            css={loadingClasses.spinnerContainer}
          >
            <ReactCSSTransitionReplace
              transitionName={animationName}
              transitionEnterTimeout={duration}
              transitionLeaveTimeout={duration}
              style={{
                height: loadingClasses.spinner.size,
                width: loadingClasses.spinner.size,
              }}
            >
              {!success ? (
                <Spinner
                  key="loading-spinner"
                  /* Using Classes here, because a bug with emotion
                  prevents passing CSS, still don't know why. */
                  classes={loadingClasses.spinner}
                />
              ) : (
                <View
                  key="success-check"
                  css={cssOverrides.success.icon}
                >
                  <Icon>check</Icon>
                </View>
              )}
            </ReactCSSTransitionReplace>
          </View>
          <View>
            <ReactCSSTransitionReplace
              transitionName="fade-wait"
              transitionEnterTimeout={duration}
              transitionLeaveTimeout={duration}
              style={loadingClasses.text}
            >
              <Text
                key={`${success ? "success" : "loading"}-text`}
                css={css(
                  loadingClasses.text,
                  !!success && cssOverrides.success.text,
                )}
              >
                {success ? success : loading}
              </Text>
            </ReactCSSTransitionReplace>
          </View>
        </View>
      </CSSTransition>
    );
  }

  render() {
    const {
      children,
      closable,
      classes: classesProp,
      css: cssOverrides,
      loading,
      onClose,
      open,
      success,
      title,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const classes = merge({}, theme.modal, classesProp, cssOverrides);

    return (
      <ReactModal
        {...other}
        isOpen={open}
        onRequestClose={onClose}
        style={{
          overlay: merge(classes.overlay.default, open && classes.overlay.open),
          content: classes.container.default,
        }}
      >
        {this.renderTitle(title)}
        {closable && (
          <Icon
            onClick={onClose}
            css={classes.close}>
            close
          </Icon>
        )}
        <View css={classes.body}>
          {children}
        </View>
        {this.renderLoadingSuccess(classes)}
      </ReactModal>
    )
  }
}

export default Modal;
