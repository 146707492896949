import { omit } from 'lodash';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  flexes,
  getFlex,
  getSpacing,
  margins,
  paddings,
} from '../../utils/component.utils';
import defaultTheme from '../../styles/theme';

import View from '../view/view.component';
import Text from '../text/text.component';
import Icon from '../icon/icon.component';
import Button from '../button/button.component';

const notifications = {
  'info-dark': {
    icon: 'alert-circle',
    lightButton: true,
  },
  'info-light': {
    icon: 'alert-circle',
    lightButton: false,
  },
  caution: {
    icon: 'alert-triangle',
    lightButton: false,
  },
  error: {
    icon: 'alert-triangle',
    lightButton: true,
  },
  success: {
    icon: 'check-circle',
    lightButton: true,
  },
};
export class Notification extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Notification';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Icon to show. */
    icon: PropTypes.string,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** The type of the notification. Variant, one of: info-dark, info-light, caution, error, success */
    type: PropTypes.oneOf(Object.keys(notifications)),
    /** If true, the remove icon will be shown.*/
    actionLabel: PropTypes.string,
    /** Callback function fired when the action button is clicked.*/
    onAction: PropTypes.func,
    /** If true, the remove icon will be shown.*/
    remove: PropTypes.bool,
    /** If true, the component will be hidden.*/
    hide: PropTypes.bool,
    /** Callback function fired when the remove icon is clicked.*/
    onRemove: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const {hide} = props;

    this.state = {
      hide,
    };
  }

  componentDidUpdate(prevProps) {
    const { hide } = this.props;
    const { hide: oldHide } = prevProps;
    if (hide !== oldHide) {
      this.setState({ hide });
    }
  }

  static defaultProps = {
    type: 'info-dark',
    remove: false,
    hide: false,
    onAction: () => {},
    onRemove: () => {},
  };

  hideNotification = () => {
    const { onRemove } = this.props;
    this.setState({ hide: true });
    onRemove();
  }

  render() {
    const {
      children,
      avatar,
      classes = {},
      css: cssOverrides = {},
      type,
      icon,
      remove,
      actionLabel,
      onAction,
      onRemove,
      ...other
    } = this.props;

    const { hide } = this.state;

    if (hide) {
      return null;
    }

    const { theme = defaultTheme } = this.context;

    const spacing = getSpacing(this.props, theme);
    const flex = getFlex(this.props);

    const remaining = omit(other, [
      ...Object.keys(flexes),
      ...Object.keys(margins),
      ...Object.keys(paddings),
    ]);

    const rootCss = css(
      theme.notification.root,
      theme.notification[type],
      spacing, 
      flex, 
      classes.root, 
      cssOverrides.root,
    );

    return (
      <View row inline css={rootCss} {...remaining}>
        <View mr={3}>
          <Icon css={
            css(
              theme.notification.icon,
              classes.icon,
              cssOverrides.icon,
            )
          }>
            {icon || (!!notifications[type] && notifications[type].icon)}
          </Icon>
        </View>
        <View fs={1} css={
            css(
              theme.notification.message,
              classes.message,
              cssOverrides.message,
            )
        }>
          <Text type="small">
            {children}
          </Text>
        </View>
        {!!actionLabel && (
          <Button
            small
            light={notifications[type].lightButton}
            css={css(theme.notification.actionButton, classes.actionButton, cssOverrides.actionButton)} onClick={onAction}
          >
            {actionLabel}
          </Button>
        )}
        {remove && (
          <Icon css={css(theme.notification.remove, classes.remove, cssOverrides.remove)} onClick={this.hideNotification}>
            close
          </Icon>
        )}
      </View>
    )
  }
}

/**
 * @component
 */
export default Notification;
