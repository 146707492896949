import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { merge } from 'lodash';
import View from '../view/view.component';
import Icon from '../icon/icon.component';
import Label from '../form-helpers/label.component';
import ErrorMessage from '../form-helpers/error-message.component';
import Helptext from '../form-helpers/help-text.component';

import defaultTheme from '../../styles/theme';

export class SelectNative extends Component {
  static displayName = 'SelectNative';

  static propTypes = {
    children: PropTypes.node,
    /** Callback function fired when an option is selected. Signature function(event: object, selectedValue) => void */
    onChange: PropTypes.func,
    /** any style overrides */
    css: PropTypes.object,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** override the ID for the element */
    id: PropTypes.string,
    /** Passed through to the native select field for testing */
    'data-test-id': PropTypes.string,
    /** If true, Select field is disabled (no user ineraction) */
    disabled: PropTypes.bool,
    /** If provided, is in error state */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    /** Passes thru the `name` attribute for forms */
    name: PropTypes.string,
    /** Optional helptext */
    helptext: PropTypes.string,
    /** Pass a string and this will be displayed if there is no value. */
    placeholder: PropTypes.string,
    /* The label content. **/
    label: PropTypes.node,
  };

  static defaultProps = {
    onChange: () => {},
    placeholder: 'Select',
    renderValue: undefined,
  }

  constructor(props) {
    super(props);
    let hasDefaultOption = false;
    let defaultValue = props.value;
    React.Children.forEach(props.children, (child) => {
      if (_get(child, 'props.selected', false)) {
        hasDefaultOption = true;
        defaultValue = child.props.value;
      }
    });
    this.state = {
      value: defaultValue,
      hasDefaultOption,
    };
  }

  // Controlled version needs to sync the state value with props value if changed after mounts.
  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({
        value,
      });
    };
  }

  onChange = (e) => {
    const { onChange, disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({
      value: e.target.value,
    });
    onChange(e, e.target.value);
  }

  render() {
    const { theme = defaultTheme } = this.context;

    const { classes, css: cssOverrides, ...other } = this.props;
    const combinedCss = merge({}, classes, cssOverrides);
    const {
      'data-test-id': dataTestId,
      children,
      disabled,
      error,
      helptext,
      id: HTMLId,
      label,
      name,
      placeholder,
      renderValue,
      onChange: _, // make sure we pull off onChange so we don't override it.
      ...rest
    } = other;
    const {
      value,
      hasDefaultOption,
    } = this.state;


    const isError = !!error;
    // Styles.
    const rootStyles = {
      ...theme.select.root,
      ...combinedCss.root,
    };
    const wrapperStyles = {
      ...theme.select.nativeWrapper,
      ...combinedCss.nativeWrapper,
      ...(disabled ? theme.select.nativeWrapperDisabled : {}),
      ...(disabled ? combinedCss.nativeWrapperDisabled : {}),
      ...(isError ? theme.select.nativeWrapperError : {}),
      ...(isError ? combinedCss.nativeWrapperError : {}),
    };
    const selectInputStyles = {
      ...theme.select.native,
      ...combinedCss.native,
      ...(value === undefined ? theme.select.nativePristine : {}),
      ...(disabled ? theme.select.nativeDisabled : {}),
      ...(disabled ? combinedCss.nativeDisabled : {}),
      ...(isError ? theme.select.nativeError : {}),
      ...(isError ? combinedCss.nativeError : {}),
    };
    const placeholderStyles = {
      ...theme.select.placeholder,
      ...combinedCss.placeholder,
    }
    const iconStyles = {
      ...theme.select.icon,
      ...combinedCss.icon,
      ...(disabled ? theme.select.iconDisabled : {}),
      ...(disabled ? combinedCss.iconDisabled : {}),
      ...(isError ? theme.select.iconError : {}),
      ...(isError ? combinedCss.iconError : {}),
    };
    const errorIconStyles = {
      ...theme.select.errorIcon,
      ...combinedCss.errorIcon,
      ...(disabled ? theme.select.errorIconDisabled : {}),
      ...(disabled ? combinedCss.errorIconDisabled : {}),
    }
    const labelStyles = {
      ...theme.select.label,
      ...combinedCss.label,
      ...(isError ? theme.select.labelError : {}),
      ...(isError ? combinedCss.labelError : {}),
    }
    const helpTextStyles = {
      ...theme.select.helpText,
      ...combinedCss.helpText,
      ...(isError ? theme.select.helpTextError : {}),
      ...(isError ? combinedCss.helpTextError : {}),
    };

    let labelComponent;
    if (label) {
      labelComponent = (
        <Label
          css={labelStyles}
        >
          {label}
        </Label>
      )
    }

    let helptextComponent = <Fragment />;
    if (helptext) {
      helptextComponent = (
        <Helptext css={helpTextStyles}>
          {helptext}
        </Helptext>
      );
    }

    return (
      <View css={rootStyles}>
        {labelComponent}
        {/* Next <View> is to reset the layout (non-flex) */}
        <View f="none" mb={1}>
          <View css={wrapperStyles}>
            <select
              css={selectInputStyles}
              onChange={this.onChange}
              data-test-id={dataTestId || 'select-field'}
              id={HTMLId}
              disabled={disabled}
              defaultValue={hasDefaultOption ? undefined : ''}
              name={name}
              {...rest}
            >
              {(placeholder && !hasDefaultOption) && (
                <option
                  disabled
                  value=""
                  css={placeholderStyles}
                  data-test-id="select-placeholder"
                >
                  {placeholder}
                </option>
              )}
              {children}
            </select>
            <Icon pl={2} css={iconStyles}>
              chevron-down
            </Icon>
            {
              isError ? (
                <Icon css={errorIconStyles}>
                  alert-triangle
                </Icon>
              ) : (null)
            }
          </View>
        </View>
        { helptextComponent}
        {!!error && React.isValidElement(error) && <ErrorMessage>{error}</ErrorMessage>}
      </View>
    )
  }
}

export default SelectNative;
