import React, { Component } from 'react';
import MaterialDrawer from '@material-ui/core/Drawer';

export class Drawer extends Component {
  static displayName = 'Drawer';

  render() {
    const { children, ...other } = this.props;

    return (
      <MaterialDrawer {...other}>
        {children}
      </MaterialDrawer>
    )
  }
}

export default Drawer;
