/*
Globally defined spacers
see: https://syl.invisionapp.com/dsm/counsyl-dpt/dp-provider/folder/components/5b9fe4d4f8da7300126ef84a
*/

export const spacing = {
  sp0: 4,
  sp1: 8,
  sp2: 12,
  sp3: 16,
  sp4: 24,
  sp5: 32,
  sp6: 48,
  sp7: 64,
  sp8: 80,
  sp9: 128,
  sp10: 168,
};
