export const textStyles = (theme) => ({
  default: {
    ...theme.typography.variants.body,
    borderWidth: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'inline',
    textDecorationLine: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  singleLine: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  onClick: {
    cursor: 'pointer',
  },
  selectable: {
    userSelect: 'none',
  },
  // TODO: Move to Link Component?
  link: {
    color: theme.color.primary.primary5,
    textDecoration: 'underline',
    fontWeight: 600,
    cursor: 'pointer',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  }
});

export default textStyles;
