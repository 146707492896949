import React, { Component }  from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import View from '../view/view.component';

import TIMELINE_STYLES from './timeline.styles';


export class TimelineNode extends Component {
  static displayName = 'TimelineNode';

  static propTypes = {
    classes: PropTypes.object,
    css: PropTypes.object,
    className: PropTypes.string,
    content: PropTypes.node,
  }

  static defaultProps ={
    classes: {},
    css: {},
    node: null,
  }

  render() {
    const {
      css: cssOverrides,
      classes,
      children,
      node,
      ...other
    } = this.props;

    let finalNode = node;
    if(node === null) {
      finalNode = '';
    }

    const finalCircleStyles = Object.assign({}, TIMELINE_STYLES.circle, classes.circle, css.circle);

    return (
      <View
        css={css(TIMELINE_STYLES.timelineNodeStyles, classes, cssOverrides)}
      >
        <View
          className="timeline-node-inner-circle"
          css={finalCircleStyles}
        >
          {finalNode}
        </View>
        <View
          className="timeline-node-inner-content"
          css={TIMELINE_STYLES.content}
          {...other}
        >
          { children }
        </View>
      </View>
    );
  }
}

export default TimelineNode;
