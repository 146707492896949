export const searchStyles = (theme) => ({
  emptyView: {
    alignItems: 'center',
    color: theme.color.neutral.neutral6,
    paddingTop: theme.spacing.sp8,
    paddingBottom: theme.spacing.sp8,
  },
  loading: {
    alignItems: 'center',
    paddingTop: theme.spacing.sp8,
    paddingBottom: theme.spacing.sp8,
  },
  results: {
    maxHeight: 360,
    overflowY: 'auto',
  },
  searchIcon: {
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: theme.spacing.sp3,
    fontSize: 22,
  },
  input: {
    root: {
      position: 'relative',
    },
    field: {
      padding: `${theme.spacing.sp2}px`,
      minWidth: 100,
      outline: 'none',
      ':focus': {
        boxShadow: 'none',
      },
    },
    wrapper: {
      backgroundColor: theme.color.neutral.white,
      border: theme.component.border.dark,
      borderRadius: theme.component.radius.r2,
      padding: `0 ${(theme.spacing.sp3 * 3) + 2}px 0 0`,
      flexWrap: 'wrap',
      ':focus-within': {
        boxShadow: `0px ${theme.spacing.sp0}px ${theme.spacing.sp1}px rgba(0, 0, 0, 0.1)`,
      },
    },
    expanded: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  menu: {
    root: {
      backgroundColor: theme.color.neutral.white,
      position: 'absolute',
      left: 0,
      right: 0,
      top: '100%',
      border: theme.component.border.dark,
      borderBottomLeftRadius: theme.component.radius.r2,
      borderBottomRightRadius: theme.component.radius.r2,
      borderTop: '0px none',
      padding: 0,
    },
    item: {
      root: {
        cursor: 'pointer',
        padding: theme.spacing.sp2,
        display: 'block',
        minHeight: 'auto',
      },
      focused: {
        backgroundColor: theme.color.neutral.neutral2,
      },
      text: {
        display: 'block',
      },
      divider: {
        margin: 0
      }
    },
  }
});
