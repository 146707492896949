import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import defaultTheme, { buildTheme } from '../../styles/theme';

export class ThemeProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    theme: PropTypes.object,
  };

  getChildContext() {
    const { theme } = this.props;
    if (theme) {
      return { theme: buildTheme(theme) };
    }
    return { theme: defaultTheme };
  }

  render() {
    const { children } = this.props;

    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }
}

export default ThemeProvider;
