import PropTypes from 'prop-types';
import React, { Component } from 'react';

import defaultTheme from '../../styles/theme';
import { PROPTYPES } from '../../utils/constants';
import View from '../view/view.component';

// TODO: make this our own ~ currently mooching off of material-ui
export class Grid extends Component {
  static displayName = 'Grid';

  static propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /** Alias for CSS */
    classes: PropTypes.object,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    css: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     * Accepts Object only as a work-around for undefined.
     */
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    /**
     * If `true`, the component will have the flex *container* behavior.
     * You should be wrapping *items* with a *container*.
     */
    container: PropTypes.bool,
    /**
     * Defines the `flex-direction` style property.
     * It is applied for all screen sizes.
     */
    direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    /**
     * If `true`, the component will have the flex *item* behavior.
     * You should be wrapping *items* with a *container*.
     */
    item: PropTypes.bool,
    /**
     * Defines the `justify-content` style property.
     * It is applied for all screen sizes.
     */
    justify: PropTypes.oneOf([
      'flex-start',
      'center',
      'flex-end',
      'space-between',
      'space-around',
      'space-evenly',
    ]),
    /**
     * Defines the number of grids the component is going to use.
     * It's applied for the `lg` breakpoint and wider screens if not overridden.
     */
    lg: PropTypes.oneOf(PROPTYPES.GRID.SIZE),
    /**
     * Defines the number of grids the component is going to use.
     * It's applied for the `md` breakpoint and wider screens if not overridden.
     */
    md: PropTypes.oneOf(PROPTYPES.GRID.SIZE),
    /**
     * Defines the number of grids the component is going to use.
     * It's applied for the `sm` breakpoint and wider screens if not overridden.
     */
    sm: PropTypes.oneOf(PROPTYPES.GRID.SIZE),
    /**
     * Defines the space between the type `item` component.
     * It can only be used on a type `container` component.
     */
    spacing: PropTypes.oneOf([0,8,16,24,32,40]),
    /**
     * Defines the `flex-wrap` style property.
     * It's applied for all screen sizes.
     */
    wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
    /**
     * Defines the number of grids the component is going to use.
     * It's applied for the `xl` breakpoint and wider screens.
     */
    xl: PropTypes.oneOf(PROPTYPES.GRID.SIZE),
    /**
     * Defines the number of grids the component is going to use.
     * It's applied for all the screen sizes with the lowest priority.
     */
    xs: PropTypes.oneOf(PROPTYPES.GRID.SIZE),
    /**
     * If `true`, it sets `min-width: 0` on the item.
     * Refer to the limitations section of the documentation to better understand the use case.
     */
    zeroMinWidth: PropTypes.bool,
  }

  static defaultProps = {
    classes: {},
    css: {},
    direction: 'row',
    wrap: 'wrap',
  }

  generateWidth(theme) {
    let result = {};
    // Order is important here, we want later rules to override earlier.
    ['xs', 'sm', 'md', 'lg', 'xl'].forEach(key => {
      const value = this.props[key];
      if (value) {
        result[theme.layout.screens[key]] = {
          flexBasis: `${Math.round((value / 12) * 100000) / 1000}%`,
        };
      }
    });
    return result;
  }

  renderChildren() {
    const { children, container } = this.props;
    // return children;
    if (container) {
      return children;
    }
    /*
    For an IE11 flex bug, we *must* wrap a naked div around the Grid container's children
    (see https://github.com/philipwalton/flexbugs#flexbug-7). We target this div specifically
    with the `div > div >` selector in the main render method.
    */
    return (
      <div>
        {children}
      </div>
    )
  }

  render() {
    const {
      type,
      classes = {},
      css: cssOverrides = {},
      className,
      component = View,
      container = false,
      item = false,
      spacing = 24,
      direction,
      wrap,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const computedStyles = item ? this.generateWidth(theme) : {};

    if (container) {
      computedStyles.flexDirection = direction;
      computedStyles.flexWrap = wrap;
      if (spacing) {
        computedStyles[' > div > div'] = {
          ...computedStyles[' > div > div'],
          ...{ padding: `${Math.floor(spacing / 2)}px` }
        };
        computedStyles.marginLeft = `-${Math.floor(spacing / 2)}px`
        computedStyles.marginRight = `-${Math.floor(spacing / 2)}px`
      }
    }

    return React.createElement(
      component,
      {
        className,
        css: {
          ...theme.grid.root,
          ...classes.root,
          ...cssOverrides,
          ...computedStyles,
        },
        ...other,
      },
      this.renderChildren(),
    );
  }

}

export default Grid;
