const DURATION = 400; // fixed at 300 for now until we can figure out CSS
const SIZE = 60;

export const modalStyles = (theme) => ({
  overlay: {
    default: {},
    open: { // disallow scrolling background when modal is open
      overflow: 'hidden',
      position: 'fixed',
      width: '100%',
      height: '100%',
    },
  },
  container: {
    default: {
      border: theme.component.border.default,
      borderRadius: theme.component.radius.default,
      bottom: 'initial',
      boxShadow: theme.component.boxShadow.b1,
      padding: 0,
      transition: `height ${DURATION}ms ease`,
      maxHeight: '90%',
    },
  },
  title: {
    ...theme.typography.variants.h3,
    ...theme.typography.colors.light,
    backgroundColor: theme.color.primary.primary8,
    position: 'relative',
    top: 0,
    width: '100%',
    left: 0,
    padding: `${theme.spacing.sp7}px 40px ${theme.spacing.sp4}px`,
  },
  body: {
    padding: 40,
  },
  close: {
    ...theme.typography.variants.h3,
    ...theme.typography.colors.light,
    position: 'absolute',
    top: theme.spacing.sp5,
    right: theme.spacing.sp5,
    cursor: 'pointer',
  },
  loading: {
    duration: DURATION,
    minWidth: 360,
    transition: theme.animation.fade(DURATION),
    transitionName: 'fade',
    animationName: 'cross-fade',
    root: {
      alignItems: 'center',
      background: theme.color.primary.primary7,
      height: '100%',
      justifyContent: 'center',
      left: 0,
      padding: theme.spacing.sp4,
      position: 'absolute',
      top: 0,
      transition: `background ${DURATION}ms ease`,
      width: '100%',
      zIndex: 1,
    },
    spinnerContainer: {
      padding: theme.spacing.sp5,
      zIndex: 2,
      overflow: 'auto',
    },
    spinner: {
      size: SIZE,
      circle: {
        '&:after': {
          background: theme.color.primary.primary7,
        },
      },
      border: {
        animation: 'none',
      },
    },
    text: {
      ...theme.typography.variants.h5,
      ...theme.typography.colors.light,
      display: 'block',
      minHeight: 30,
      minWidth: 360,
      overflow: 'auto',
      textAlign: 'center',
    }
  },
  success: {
    root: {
      background: theme.color.messages.success,
    },
    icon: {
      alignItems: 'center',
      background: theme.color.neutral.white,
      borderRadius: '50%',
      boxShadow: theme.component.boxShadow.b1,
      color: theme.color.messages.success,
      fontSize: 20,
      height: SIZE,
      justifyContent: 'center',
      width: SIZE,
    },
  }
});

export default modalStyles;
