import React, { Component } from 'react';
import PropTypes from 'prop-types';

import defaultTheme from '../../styles/theme';
import View from '../view/view.component';
import Label from '../form-helpers/label.component';
import ErrorMessage from '../form-helpers/error-message.component';
import Helptext from '../form-helpers/help-text.component';

const cssPropShape = PropTypes.shape({
  /** general styles */
  root: PropTypes.object,
  /** label styles */
  label: PropTypes.object,
  /** radio button styles styles */
  radio: PropTypes.object,
});

/**
 * A group of Radio Buttons
 */
export class RadioGroup extends Component {
  static propTypes = {
    /** name for the fields, important for Form elements */
    name: PropTypes.string,
    /** Label for the radio-group */
    label: PropTypes.string,
    /** Checked value of the radio buttons */
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    /* helper text **/
    helptext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /* error message **/
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /** Variant, display radios in a row, by default they are in a column */
    inline: PropTypes.bool,
    /** Variant display small radios */
    small: PropTypes.bool,

    /** Base styles for the radio-group. */
    css: cssPropShape,
    /** Customize the styles of the radio-group. */
    classes: cssPropShape,

    /** Callback fired when the value changes. */
    onChange: PropTypes.func,
    /** Callback fired when the radio buttons are blurred. */
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    value: undefined,
    onChange: () => {},
    onBlur: () => {},
    inline: false,
    small: false,
    children: '',
    classes: {},
  }

  state = {
    selection: undefined,
  }

  componentDidMount() {
    // Set state via props, or via the state of the children components.
    const { value, children } = this.props;
    const childValue = React.Children.map(children, (child) => {
      if (child.props.checked) {
        return child.props.value;
      }
    });
    this.setState({ selection: value || childValue[0]})
  }

  setSelection = (event) => {
    const { onChange } = this.props;
    this.setState({ selection: event.target.value });
    onChange(event);
  }

  render() {
    const {
      name,
      label,
      inline,
      defaultValue,
      onChange,
      onBlur,
      children,
      helptext,
      error,
      small,
      classes,
      ...otherProps
    } = this.props;

    const { theme = defaultTheme } = this.context;
    let radioGroupStyles = inline ? theme.radioGroup.inline : theme.radioGroup.stacked;

    return (
      <View
        {...otherProps}
        classes={classes.root}
      >
        <Label classes={classes.label}>
          {label}
        </Label>
        <View
          css={radioGroupStyles}
          row={inline}
        >
          {
            React.Children.map(children, (child) => {
              const checked = child.props.value === this.state.selection;
              const { value, ...otherChildProps } = child.props;
              return (
                React.cloneElement(
                  child,
                  {
                    ...otherChildProps,
                    // Use prop from the parent, not from child.
                    name: name,
                    onChange: this.setSelection,
                    small: small,
                    checked: checked,
                    classes: classes.radio,
                  }
                )
              )
            })
          }
        </View>
        <Helptext>{helptext}</Helptext>
        <ErrorMessage>{error}</ErrorMessage>
      </View>
    );
  }
}

/**
 * @component
 */
export default RadioGroup;
