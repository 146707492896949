import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { css } from '@emotion/core';

import { Card } from '../card/card.component';
import { PanelHeader } from './panel-header.component';
import { Collapse } from '../collapse/collapse.component';
import { Divider } from '../divider/divider.component';
import { Icon } from '../icon/icon.component';
import { View } from '../view/view.component';

import defaultTheme from '../../styles/theme';

export class Panel extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Panel';

  static propTypes = {
    /** The content of the panel. */
    children: PropTypes.node,
    /** Alias for css. */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** If `true`, expands the panel, otherwise collapse it. */
    expanded: PropTypes.bool,
    /** Triggered when the expansion button is toggled. */
    onChange: PropTypes.func,
    /** Optional Icon to render in header, ignored if `header` is a component */
    icon: PropTypes.node,
    /** Header for the panel. */
    header: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
  };

  renderIcon = () => {
    const { theme = defaultTheme } = this.context;

    const {
      classes = {},
      css: cssOverrides = {},
      expanded,
      icon,
    } = this.props;

    const combinedCss = Object.assign({}, classes, cssOverrides);

    return icon ||  (
      <Icon
        classes={css(
          combinedCss.icon || theme.panel.icon,
          expanded && (combinedCss.iconExpanded || theme.panel.iconExpanded),
        )}
      >
        chevron-up
      </Icon>
    );
  }

  render() {
    const { theme = defaultTheme } = this.context;

    const {
      classes = { header: {} },
      css: cssOverrides = { header: {} },
      children,
      expanded,
      onChange = () => {},
      icon,
      header,
      ...other
    } = this.props;

    let headerComponent;

    const combinedCss = merge({}, classes, cssOverrides);

    if(header) {
      if(typeof header === 'string') {
        headerComponent = (
          <PanelHeader
            classes={merge({}, theme.panel.header, combinedCss.header)}
            onClick={onChange}
            title={header}
          >
          {this.renderIcon()}
          </PanelHeader>
        );
      } else {
        headerComponent = React.cloneElement(header, {
          onClick: onChange,
          ...other,
        });
      }
    }

    return (
      <Card
        css={css(theme.panel.root, combinedCss.root)}
        {...other}
      >
        {headerComponent}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {(expanded && typeof header === 'string') && <Divider css={css(theme.panel.divider, combinedCss.divider)}/>}
          <View css={css(theme.panel.content, combinedCss.content)}>
            {children}
          </View>
        </Collapse>
      </Card>
    );
  }
}

export default Panel;
