/** @jsx jsx */
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { css, jsx } from '@emotion/core';

import {
  flexes,
  getFlex,
  getSpacing,
  margins,
  paddings,
} from '../../utils/component.utils';

import defaultTheme from '../../styles/theme';

/**
 * The only true view.
 *
 * @export
 * @class View
 * @extends {Component}
 */
export class View extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'View';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for CSS. */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** flex, set to "none" to turn flex off. */
    f: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** flex-basis */
    fb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** flex-grow */
    fg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** flex-shrink */
    fs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** margin / padding
     *
     * apply theme defined spacing based on number
     *
     * e.g. `m={1}` will become `{ margin: theme.spacing.sp1 }`.
     *
     * This rule is true of all the following margin and padding props.
    */
    m: PropTypes.number,
    /** marginTop */
    mt: PropTypes.number,
    /** marginRight */
    mr: PropTypes.number,
    /** marginBottom */
    mb: PropTypes.number,
    /** marginLeft */
    ml: PropTypes.number,
    /** marginLeft and marginRight */
    mx: PropTypes.number,
    /** marginTop and marginBottom */
    my: PropTypes.number,
    /** padding */
    p: PropTypes.number,
    /** paddingTop */
    pt: PropTypes.number,
    /** paddingRight */
    pr: PropTypes.number,
    /** paddingBottom */
    pb: PropTypes.number,
    /** paddingLeft */
    pl: PropTypes.number,
    /** paddingLeft and paddingRight */
    px: PropTypes.number,
    /** paddingTop and paddingBottom */
    py: PropTypes.number,
    /** Should the component set flex-direction: row */
    row: PropTypes.bool,
    /** Should the component display inline-flex */
    inline: PropTypes.bool,
  };

  render() {
    const {
      innerRef = () => {},
      row,
      inline,
      classes,
      css: cssOverrides,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const spacing = getSpacing(this.props, theme);
    const flex = getFlex(this.props);
    const remaining = _.omit(other, [
      ...Object.keys(flexes),
      ...Object.keys(margins),
      ...Object.keys(paddings),
    ]);

    return (
      <div
        css={css(
          theme.view.default,
          row && theme.view.row,
          inline && theme.view.inline,
          flex,
          classes,
          cssOverrides,
          spacing,
        )}
        {...remaining}
        ref={innerRef}
      >
        {this.props.children}
      </div>
    );
  }
}

/**
 * @component
 */
export default View;
