import _ from 'lodash';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  flexes,
  getFlex,
  getSpacing,
  margins,
  paddings,
} from '../../utils/component.utils';
import defaultTheme from '../../styles/theme';

import View from '../view/view.component';
import Icon from '../icon/icon.component';

export class Chip extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Chip';

  static propTypes = {
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** The content of the label. */
    label: PropTypes.node,
    /** Callback function fired when the remove icon is clicked.
     * If set, the remove icon will be shown.
     */
    onRemove: PropTypes.func,
  };

  render() {
    const {
      avatar,
      classes = {},
      css: cssOverrides = {},
      label,
      onRemove,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const spacing = getSpacing(this.props, theme);
    const flex = getFlex(this.props);

    const remaining = _.omit(other, [
      ...Object.keys(flexes),
      ...Object.keys(margins),
      ...Object.keys(paddings),
    ]);

    let avatarElement;
    if (avatar) {
      avatarElement = React.cloneElement(avatar, {
        classes: css(theme.chip.avatar, classes.avatar, cssOverrides.avatar, avatar.props.classes),
        css: css(theme.chip.avatar, classes.avatar, cssOverrides.avatar, avatar.props.classes),
      });
    }

    return (
      <View css={css(theme.chip.root, spacing, flex, classes.root, cssOverrides.root)} {...remaining}>
        {avatarElement}
        <View css={css(theme.chip.label, classes.label, cssOverrides.label)}>
          {label}
        </View>
        {!!onRemove && (
          <Icon css={css(theme.chip.remove, classes.remove, cssOverrides.remove)} onClick={onRemove}>
            close
          </Icon>
        )}
      </View>
    )
  }
}

/**
 * @component
 */
export default Chip;
