import Button from './components/button/button.component';
import Card from './components/card/card.component';
import CardContent from './components/card/card-content.component';
import CardHeader from './components/card/card-header.component';
import Checkbox from './components/checkbox/checkbox.component';
import CheckboxGroup from './components/checkbox-group/checkbox-group.component';
import Chip from './components/chip/chip.component';
import Collapse from './components/collapse/collapse.component';
import { defaultTheme } from './styles/theme';
import Divider from './components/divider/divider.component';
import Drawer from './components/drawer/drawer.component';
import ErrorMessage from './components/form-helpers/error-message.component';
import Grid from './components/grid/grid.component';
import HelpText from './components/form-helpers/help-text.component';
import Icon from './components/icon/icon.component';
import Label from './components/form-helpers/label.component';
import List, { ListItem } from './components/list/list.component';
import Menu, { MenuItem } from './components/menu/menu.component';
import Modal from './components/modal/modal.component';
import ModalTitle from './components/modal/modal-title.component';
import Navigation from './components/navigation/navigation.component';
import NavigationTab from './components/navigation/navigation-tab.component';
import Notification from './components/notification/notification.component';
import Panel from './components/panel/panel.component';
import Placeholder from './components/placeholder/placeholder.component';
import Popover from './components/popover/popover.component'
import Progress from './components/progress/progress.component';
import Radio from './components/radio/radio.component';
import RadioGroup from './components/radio-group/radio-group.component';
import Search from './components/search/search.component';
import SearchInput from './components/search/search-input.component';
import SearchMenu from './components/search/search-menu.component';
import SearchMenuItem from './components/search/search-menu-item.component';
import Select from './components/select/select.component';
import SelectNative from './components/select-native/select-native.component';
import Snackbar from './components/snackbar/snackbar.component';
import Spinner from './components/spinner/spinner.component';
import Slider from './components/slider/slider.component';
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from './components/table/table.component';
import Text from './components/text/text.component';
import TextField from './components/text-field/text-field.component';
import TextInput from './components/text-input/text-input.component';
import ThemeProvider from './components/theme-provider/theme-provider.component';
import Timeline from './components/timeline/timeline.component';
import TimelineNode from './components/timeline/timeline-node.component';
import Tooltip from './components/tooltip/tooltip.component';
import View from './components/view/view.component';
import styles from './styles';
import { colorWithAlpha } from './utils/component.utils';

const { MediaQuery } = styles.layout;
const Styles = defaultTheme;

const utils = {
  colorWithAlpha,
};



/**
 * Announces the current version of Primase, importing version number from package.json
 */
const announce = () => {
  const pjson = require('../../package.json');
  try {
    if (window && window.console && window.console.log) {
        console.log(`=== Using Primase ${pjson.version} ===`);
    }
  } catch (e) {
    return;
  }
}
announce();

export {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  Chip,
  Collapse,
  Divider,
  Drawer,
  ErrorMessage,
  Grid,
  HelpText,
  Icon,
  Label,
  List,
  ListItem,
  MediaQuery,
  Menu,
  MenuItem,
  Modal,
  ModalTitle,
  Navigation,
  NavigationTab,
  Notification,
  Panel,
  Placeholder,
  Popover,
  Progress,
  Radio,
  RadioGroup,
  Search,
  SearchInput,
  SearchMenu,
  SearchMenuItem,
  Select,
  SelectNative,
  Snackbar,
  Spinner,
  Slider,
  Styles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextField,
  TextInput,
  ThemeProvider,
  Timeline,
  TimelineNode,
  Tooltip,
  View,
  utils,
};
