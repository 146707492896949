import convert from 'color-convert';

import * as color from './color';

/* BORDER RADIUS */
const radius = {
  r1: 3,
  r2: 4,
  r3: 10,
  r4: 12,
}

radius.default = radius.r3;

/* BOX SHADOWS */
// parse and convert hex color string to rgb array.
const darkGreyRGB = convert.hex.rgb(color.neutral.neutral6.replace('#', ''));

const boxShadow = {
  b1: `4px 4px 30px 4px rgba(${darkGreyRGB.join(', ')}, 0.1)`,
  b2: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  b3: `0px 4px 6px 4px rgba(${darkGreyRGB.join(', ')}, 0.02)`,
  b4: `0px 1px 2px 1px rgba(${darkGreyRGB.join(', ')}, 0.07)`,
}

boxShadow.default = boxShadow.b2;

const innerShadow = {
  nav: `inset 1px 1px 10px 0 rgba(0, 0, 0, 0.08)`,
}

/* BORDERS */
const medGreyRGB = convert.hex.rgb(color.neutral.neutral4.replace('#', ''));

const border = {
  active: `4px solid ${color.primary.primary5}`,
  inactive: `4px solid transparent`,
  default: `1px solid rgba(${medGreyRGB.join(', ')}, 0.2)`,
  dashed: `1px dashed ${color.neutral.neutral4}`,
  light: `1px solid ${color.neutral.neutral2}`,
  neutral: `1px solid ${color.neutral.neutral3}`,
  dark: `1px solid ${color.neutral.neutral4}`,
};

export default {
  border,
  boxShadow,
  innerShadow,
  radius,
};
