export const navigationStyles = (theme) => ({
  root: {
    alignItems: 'stretch',
    boxShadow: theme.component.boxShadow.b4,
    padding: `0 ${theme.spacing.sp2}px`,
  },
  prefix: {
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: theme.spacing.sp1,
    justifyContent: 'flex-end',
  },
  suffix: {
    alignItems: 'center',
    paddingLeft: theme.spacing.sp1,
    paddingRight: 0,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  tab: {
    default: {
      padding: `${theme.spacing.sp4}px ${theme.spacing.sp4}px `,
      userSelect: 'none',
      cursor: 'pointer',
      ...theme.typography.colors.dark,
      ...theme.typography.weights.bold,
      '&:hover': {
        color: theme.color.primary.primary8,
      },
      '&:hover a': {
        color: theme.color.primary.primary8,
      },
      ' a': {
        ...theme.typography.colors.dark,
        textDecoration: 'none',
      }
    },
    active: {
      color: theme.color.primary.primary8,
      '&:after': {
        content:  "'\\00a0'",
        display: 'block',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderTop: theme.component.border.active,
      },
      ' a': {
        color: theme.color.primary.primary8,
        textDecoration: 'none',
      }
    }
  },
});

export default navigationStyles;
