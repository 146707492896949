import checkedIcon from './icons/checked.svg';

// TODO: Add indeterminate styles.
export const checkboxStyles = (theme) => ({
  input: {
    cursor: 'inherit',
    border: `2px solid ${theme.color.primary.primary4}`,
    borderRadius: '3px',
    appearance: 'none',
    width: 18,
    height: 18,
    margin: 0,
    marginRight: '0.5rem',
    padding: 0,
    flexShrink: 0,
    ':checked': {
      background: `${theme.color.primary.primary6} url(${checkedIcon}) center center no-repeat`,
      backgroundSize: 'contain',
      borderColor: theme.color.primary.primary6,
    },
    ':disabled': {
      borderColor: theme.color.neutral.neutral4,
    },
    ':disabled:hover': {
      borderColor: theme.color.neutral.neutral4,
    },
    ':checked:disabled': {
      backgroundColor: theme.color.neutral.neutral4,
    },
    ':checked:disabled:hover': {
      backgroundColor: theme.color.neutral.neutral4,
      borderColor: theme.color.neutral.neutral4,
    },
    ':focus': {
      boxShadow: `0 0 0 2px ${theme.color.neutral.white}, 0 0 0 4px ${theme.color.primary.primary5}`,
      borderRadius: '2px',
      outline: 'none !important',
    },
    ':hover': {
      borderColor: theme.color.primary.primary6,
    },
    ':checked:hover': {
      borderColor: theme.color.primary.primary6,
      backgroundColor: theme.color.primary.primary6,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: theme.color.black,
    ...theme.typography.variants.body,
  },
  disabled: {
    color: theme.color.neutral.neutral4,
  },
  smallLabel: {
    ...theme.typography.variants.small,
  },
  smallInput: {
    width: 15,
    height: 15,
  }
});

export default checkboxStyles;
