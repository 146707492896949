import { merge }  from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

import View from '../view/view.component';

const minTextSize = 10;

export class Text extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Text';

  static propTypes = {
    children: PropTypes.node,
    /** Alias of css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** Truncates the text with an ellipsis after this many lines. Currently only supports `1`. */
    numberOfLines: PropTypes.number,
    /** When false, the text is not selectable. */
    selectable: PropTypes.bool,
    /** When true, the text will adapt to shrink to it's container. Use with caution, Not performant. Doesn't re-run on window resize */
    resizeText: PropTypes.bool,
    /** Type/Size Variant, one of: hero, h1 - h6, body, button, small, tiny. */
    type: PropTypes.oneOf(Object.keys(defaultTheme.typography.variants)),
    /** Color, one of the default text theme colors:  inherit, base, light, dark, error, primary */
    color: PropTypes.oneOf(Object.keys(defaultTheme.typography.colors)),
    /** Weight, one of the default text weights: light, normal, bold */
    weight: PropTypes.oneOf(Object.keys(defaultTheme.typography.weights)),
    /** Link, is this a link, or should it appear as a link? */
    link: PropTypes.bool,
  };

  static defaultProps = {
    selectable: true,
    resizeText: false,
    link: false,
  };

  state = {
    originalTextSize: null,
    overrideTextSize: null,
  }

  componentDidMount() {
    const { resizeText } = this.props;
    if(resizeText && this.textRef) {
      this.resizeText();
    }
  }

  componentDidUpdate() {
    const { resizeText } = this.props;
    if(resizeText && this.textRef) {
      this.resizeText();
    }
  }

  resizeText() {
    const { originalTextSize } = this.state;
    const newState = {};
    const { scrollWidth, clientWidth } = this.textRef;
    if (!scrollWidth || !clientWidth) {
      return; // exit with no-op.
    }
    const currSize = this.getFontSize();
    if (!originalTextSize) { // Set the inital text size.
      newState.originalTextSize = currSize;
    }
    if (scrollWidth > clientWidth && currSize > minTextSize) { // text too big.
      newState.overrideTextSize = currSize - 1;
      window.requestAnimationFrame(() => {
        this.setState(newState);
      });
    }
  }

  getFontSize() {
    return parseInt(window.getComputedStyle(this.textRef)['fontSize'], 10);
  }

  render() {
    const {
      classes,
      css: cssOverrides = {},
      type,
      color,
      weight,
      numberOfLines,
      onClick,
      resizeText,
      selectable,
      link,
      ...other
    } = this.props;
    const { overrideTextSize } = this.state;
    const { theme = defaultTheme } = this.context;

    const combinedCss = merge({}, classes, cssOverrides);
    return (
      <View
        css={css(
          theme.text.default,
          link && theme.typography.link,
          theme.typography.variants[(type || 'body')],
          color && theme.typography.colors[color],
          weight && theme.typography.weights[weight],
          numberOfLines === 1 && theme.text.singleLine,
          !selectable && theme.text.selectable,
          onClick && theme.text.onClick,
          resizeText && theme.text.nowrap,
          overrideTextSize && {
            fontSize: overrideTextSize,
          },
          combinedCss,
        )}
        onClick={onClick}
        innerRef={(el) => this.textRef = el}
        {...other}
      >
        {this.props.children}
      </View>
    )
  }
}

export default Text;
