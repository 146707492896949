import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { View } from '../view/view.component';
export { default as ListItem } from './list-item.component';

const styles = {
  default: {
    backgroundColor: '#fff',
    padding: '8px 0',
  },
};

/**
 * The only true list.
 */
export class List extends Component {
  static displayName = 'List';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** Height of the list */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Width of the list */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      children,
      height,
      width,
      ...other
    } = this.props;

    return (
      <View
        css={css(styles.default, classes, cssOverrides)}
        style={{ width, height, maxWidth: width, maxHeight: height }}
        {...other}
      >
        {children}
      </View>
    )
  }
}

/**
 * @component
 */
export default List;
