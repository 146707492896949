import convert from 'color-convert';

// reading a dimension prop will cause the browser to recalculate,
// which will let our animations work
export const reflow = node => node.scrollTop;

/**
 * Safely return transition properties for animation
 *
 * @param {Object} props - transition style properties and timeout details
 * @param {Object} options - information about transition state e.g. mode is 'enter', 'exit'
 * @returns {Object} - the duration and delay in ms of a transition
 */
export const getTransitionProps = (props, options) => {
  const { timeout, style = {} } = props;

  return {
    duration:
      style.transitionDuration || typeof timeout === 'number' ? timeout : timeout[options.mode],
    delay: style.transitionDelay,
  };
}

/**
 * Safely return the autocomputed duration of a height resizing animation
 * Small heights will expand/collapse quickly
 * larger heights will expand/collapse with longer duration (but hopefully reasonable)
 * We'll animate the transition using this computed duration
 *
 * @param {float} height - the height (typically px) expand/collapse we are animating
 * @returns {float} - duration in ms of the animation
 */
export const getAutoHeightDuration = (height) => {
  if (!height) {
    return 0;
  }

  const constant = height / 36;

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
};



/**
 * latestPromise() is hard to explain.
 * promise-latest gives you a function that can be used to decorate a
 * promise-returning function to make sure multiple calls to it always
 * resolve with the value from the most recent promise returned by the
 * original function.
 * If a promise returning function gets invoked and the promise returned
 * by the previous call is still in progress, the previously returned
 * promise(s) will instead be resolved with the value from the promise
 * returned by the most recent call.
 *
 * In other words, use this method when you have multiple promises
 * resolving out of order, and you only care about the last resulting promise.
 * This is mostly useful for type-ahead sort of features.
 */
// inspired by https://github.com/bjoerge/promise-latest/blob/master/index.js
export const latestPromise = (fn) => {
  let pending, resolve, reject, lastAdded;

  function fulfill(promise, value) {
    // this promise fulfilled!
    if (promise === lastAdded) {
      pending = null; // reset our wrapper promise.
      resolve(value); // our wrapper promise resolved!
    }
  }
  function fail(promise, error) {
    pending = null; // reset our wrapper promise.
    reject(error);
  }

  return (...args) => {
    // save this new promise to a ref.
    lastAdded = fn(...args);
    if (!pending) {
      // save a ref to our new promise.
      pending = new Promise((_resolve, _reject) => {
        // map our new promises to shared scope resolve reject.
        resolve = _resolve;
        reject = _reject;
      })
    }
    //
    lastAdded.then(fulfill.bind(null, lastAdded), fail.bind(null, lastAdded));
    // return our wrapper promise.
    return pending;
  }
}

const directions = {
  t: ['Top'],
  r: ['Right'],
  b: ['Bottom'],
  l: ['Left'],
  x: ['Left', 'Right'],
  y: ['Top', 'Bottom'],
}

const spacingPrefix = 'sp';

/**
 * Margin props (m, mx, my, mt, mb, ml, mr)
 */
export const margins = { m: ['margin'] };
Object.keys(directions).forEach(dir => {
  margins[`m${dir}`] = directions[dir].map(i => `margin${i}`);
});

/**
 * Padding props (p, px, py, pt, pb, pl, pr)
 */
export const paddings = { p: ['padding'] };
Object.keys(directions).forEach(dir => {
  paddings[`p${dir}`] = directions[dir].map(i => `padding${i}`);
});

/**
 * Convert margin and padding props to CSS in JS styles
 * based on a defined theme
 *
 * @param {Object} props - react component props
 * @param {Object} theme - theme specification
 * @param {Object} theme.spacing - spacing classification, keys are sp1, sp2, etc.
 * @returns {Object} - shape will be in CSS in JS form e.g. {marginRight: 12, paddingLeft: 2}
 */
export const getSpacing = (props, theme) => {
  const spacing = {};
  for(const key of Object.keys(props)) {
    if (!!margins[key]) {
      margins[key].forEach(style => {
        spacing[style] = props[key] !== undefined ? theme.spacing[`${spacingPrefix}${props[key].toString()}`] : ''
      });
    } else if (!!paddings[key]){
      paddings[key].forEach(style => {
        spacing[style] = props[key] !== undefined  ? theme.spacing[`${spacingPrefix}${props[key].toString()}`] : ''
      });
    }
  }

  return spacing;
}

export const flexes = {
  f: 'flex',
  fg: 'flexGrow',
  fb: 'flexBasis',
  fs: 'flexShrink',
};

export const getFlex = (props) => {
  const styles = {};
  Object.keys(flexes).forEach(key => {
    if (props[key] !== undefined) {
      styles[flexes[key]] = props[key];
    }
  });
  // fix IE11 issues.
  if (Object.keys(props).indexOf('fb') === -1 && Object.keys(props).indexOf('f') !== -1) {
    styles[flexes['fb']] = 'auto';
  }
  // Allow us to set a view to non-flex with `f="none"`.
  if (props.f && props.f === 'none') {
    delete styles['flex'];
    styles.display = 'block';
  }
  return styles;
}

export const colorWithAlpha = (color, alpha) => (
  `rgba(${
    convert.hex.rgb(color.replace('#', ''))
      .join(', ')
  }, ${alpha})`
);
