import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';
import '../../assets/fonts/fonts.css';

import View from '../view/view.component';

export class Icon extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Icon';

  static propTypes = {
    /** Alias for css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /* Pass the name of the icon to be rendered as the child **/
    children: PropTypes.node,
    /* The color of the icon **/
    color: PropTypes.string,
    /* The font size of the icon **/
    fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /* Render the icon with light styles **/
    light: PropTypes.bool,
  }

  render() {
    const {
      children,
      classes,
      css: cssOverrides,
      className: classNameProp,
      color,
      fontSize,
      light,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const className = classNames({
      [`icon-${children}`]: children,
      [classNameProp]: classNameProp,
    });

    return (
      <View
        css={css(
          color && { color },
          fontSize && { fontSize },
          light && { color: theme.color.white },
          classes,
          cssOverrides,
        )}
        className={className}
        aria-hidden="true"
        {...other}
      />
    );
  }
}

export default Icon;
