import { keyframes } from '@emotion/core';

const THICKNESS = 12; // thickness of loader

const shimmer = keyframes({
  '0%': {
    backgroundPosition: '100% 0',
  },
  '100%': {
    backgroundPosition: '-100% 0',
  },
});

export const progressStyles = (theme) => ({
  root: {
    height: THICKNESS,
  },
  bar: {
    backgroundImage: `linear-gradient(to right, ${theme.color.primary.primary5} , ${theme.color.primary.primary2})`,
    height: '100%',
    transition: 'width 0.3s ease-in',
  },
  shimmer: {
    animationDuration: '2s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: `${shimmer}`,
    animationTimingFunction: 'linear',
    background: `linear-gradient(to right, ${theme.color.primary.primary2}, ${theme.color.primary.primary5}, ${theme.color.primary.primary2})`,
    backgroundSize: '200% 100%',
    position: 'relative',
  },
});

export default progressStyles;
