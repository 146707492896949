export const cardStyles = (theme) => ({
  root: {
    overflow: 'hidden',
    background: theme.color.neutral.white,
  },
  rounded: {
    borderRadius: theme.component.radius.default,
  },
  raised: {
    boxShadow: theme.component.boxShadow.b2,
  },
  content: {
    padding: `${theme.spacing.sp4}px`,
  },
  header: {
    root: {
      padding: `${theme.spacing.sp4}px ${theme.spacing.sp4}px ${theme.spacing.sp3}px`,
    },
    title: {
      ...theme.typography.variants.h4,
    },
    variants: {
      border: {
        '&:after': {
          content:  '"\\00a0"',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          lineHeight: 0,
          left: `${theme.spacing.sp4}px`,
          right: `${theme.spacing.sp4}px`,
          borderBottom: theme.component.border.neutral,
        }
      },
      fill: {
        background: theme.color.primary.primary9,
        color: theme.color.neutral.white,
      }
    },
  }
});

export default cardStyles;
