/** @jsx jsx */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { jsx, css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

export class TextInput extends Component {
  static displayName = 'TextInput';

  static propTypes = {
    autoCapitalize: PropTypes.oneOf(['characters', 'none', 'sentences', 'words']),
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoFocus: PropTypes.bool,
    blurOnSubmit: PropTypes.bool,
    /** Override or extend the styles applied to the component. */
    classes: PropTypes.object,
    clearTextOnFocus: PropTypes.bool,
    defaultValue: PropTypes.string,
    editable: PropTypes.bool,
    keyboardType: PropTypes.oneOf([
      'default',
      'email-address',
      'number-pad',
      'numeric',
      'phone-pad',
      'search',
      'url',
      'web-search'
    ]),
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    numberOfLines: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onChangeText: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onSubmitEditing: PropTypes.func,
    placeholder: PropTypes.string,
    secureTextEntry: PropTypes.bool,
    selectTextOnFocus: PropTypes.bool,
    selection: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number
    }),
    value: PropTypes.string,
    /* react-native compat */
    caretHidden: PropTypes.bool,
    clearButtonMode: PropTypes.string,
    dataDetectorTypes: PropTypes.string,
    disableFullscreenUI: PropTypes.bool,
    enablesReturnKeyAutomatically: PropTypes.bool,
    keyboardAppearance: PropTypes.string,
    inlineImageLeft: PropTypes.string,
    inlineImagePadding: PropTypes.number,
    onContentSizeChange: PropTypes.func,
    onEndEditing: PropTypes.func,
    onScroll: PropTypes.func,
    placeholderTextColor: PropTypes.string,
    returnKeyLabel: PropTypes.string,
    returnKeyType: PropTypes.string,
    selectionState: PropTypes.any,
    spellCheck: PropTypes.bool,
    textBreakStrategy: PropTypes.string,
    underlineColorAndroid: PropTypes.string,
  }

  static defaultProps = {
    autoCapitalize: 'sentences',
    autoComplete: 'on',
    autoCorrect: 'true',
    editable: true,
    keyboardType: 'default',
    multiline: false,
    numberOfLines: 2,
    secureTextEntry: false,
  };

  render() {
    const {
      css: cssOverrides,
      classes,
      keyboardType,
      numberOfLines,
      secureTextEntry,
      editable,
      multiline,
      error,
      inputRef,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;
    const InputComponent = multiline ? 'textarea' : 'input';

    return (
      <InputComponent
        css={css(
            theme.textInput.default,
            classes,
            cssOverrides,
            error && theme.textInput.error,
        )}
        ref={inputRef}
        readOnly={!editable}
        {...other}
      />
    );
  }
}

export default TextInput;
