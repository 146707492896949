/* Named Colors:
 * Use these when the color matters, i.e.
 * If help text said somethign like:
 * "Please click the Green box."
 * it is important that green always be green, so use
 * the exported green variable.
 */
;

export const gray = {
  0: '#ffffff',
  100: '#F9FBFB',
  200: '#F4F6F7',
  300: '#E3E7E9',
  400: '#BEC3C6',
  500: '#8B959A',
  600: '#6F787D',
  700: '#525A5F',
  800: '#36393B',
};


export const blue = {
  100: '#F1F7FC',
  200: '#E0EEF8',
  300: '#B9D8ED',
  400: '#88BBDC',
  500: '#55A9DF',
  600: '#377DA9',
  700: '#055C94',
  800: '#044B78',
  900: '#09314A',
};

export const green = {
  500: '#359C88',
  700: '#0A6553',
};

export const red = {
  500: '#CE5448',
  700: '#A42E28',
};

export const yellow = {
  300: '#FFBF5A',
  500: '#E7A844',
};

/* Classified Colors
 * Use these when the context matters, i.e.
 * In form errors, use the secondary.error color.
 * A color to represent an error could vary by
 * culture for example (maybe errors are typically shown in blue)
 * therefore, when the color conveys some meaning, please use
 * classified color variables below.
 */


export const neutral = {
  white: gray[0],
  neutral1: gray[100],
  neutral2: gray[200],
  neutral3: gray[300],
  neutral4: gray[400],
  neutral5: gray[500],
  neutral6: gray[600],
  neutral7: gray[700],
  black: gray[800],
};

export const primary = {
  primary1: blue[100],
  primary2: blue[200],
  primary3: blue[300],
  primary4: blue[400],
  primary5: blue[500],
  primary6: blue[600],
  primary7: blue[700],
  primary8: blue[800],
  primary9: blue[900],
};

export const result = {
  result1: green[500],
  result2: blue[500],
  result3: yellow[500],
  result4: red[500],
  result5: gray[500],
};

export const messages = {
  success: green[500],
  'success-bkgd': green[700],
  caution: yellow[500],
  'caution-bkgd': yellow[300],
  error: red[500],
  'error-bkgd': red[700],
};
