import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

import View from '../view/view.component';

const classesPropTypes = PropTypes.shape({
  root: PropTypes.object,
  prefix: PropTypes.object,
  suffix: PropTypes.object,
});

/** Navigation Container
 *
 * Usually displays at the top of a page.
 *
 * Write your own nav elements using `<NavigationTab/>`.
 *
 * Optionally include prefix and suffix for elements that will render
 * at the beginning and end of the nav bar
*/
export class Navigation extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Navigation';

  static propTypes = {
    /** A component that appears to the left of the children of nav, such as a logo. */
    prefix: PropTypes.node,
    /** A component that appears to the right of the children of nav, such as account info. */
    suffix: PropTypes.node,
    /** Usually an array of NavigationTabs, the navigation components, can be any component. */
    children: PropTypes.node,
    /** Alias for CSS */
    classes: classesPropTypes,
    /** css.root styles the nav container, classes.prefix and classes.suffix style their respective components. */
    css: classesPropTypes,
  };

  static defaultProps = {
    classes: {},
    css: {},
  };

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      children,
      prefix,
      suffix,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;
    const combinedCss = Object.assign({}, classes, cssOverrides);
    return (
      <View
        row
        css={css(theme.navigation.root, combinedCss.root)}
        {...other}
      >
        {prefix && (
          <View row css={css(theme.navigation.prefix, combinedCss.prefix)}>
            {prefix}
          </View>
        )}
        {children}
        {suffix && (
          <View
            row
            css={css(theme.navigation.suffix, combinedCss.suffix)}
          >
            {suffix}
          </View>
        )}
      </View>
    );
  }
}

export default Navigation;
