import { css } from '@emotion/core';

// fade in/out animation
export const fade = (duration, name = 'fade') => css({
  [`&.${name}-enter`]: {
    opacity: 0,
    transition: `all ${duration}ms ease-out`,
  },
  [`&.${name}-enter-active`]: {
    opacity: 1,
    transition: `all ${duration}ms ease-out`,
  },
  [`&.${name}-exit`]: {
    opacity: 1,
    transition: `all ${duration}ms ease-out`,
  },
  [`&.${name}-exit-active`]: {
    opacity: 0,
    transition: `all ${duration}ms ease-out`,
  }
});

// Material UI Approved animation timing function, see:
// https://material.io/design/motion/speed.html#easing
export const materianQuantTiming = 'cubic-bezier(0.4, 0.0, 0.2, 1)';
