import { keyframes } from '@emotion/core';

const THICKNESS = 10; // thickness of spinner
const SIZE = 60; // width/height of spinner
const DURATION = 500; // duration of enter/leave animations

// spinning animation
const spin = keyframes({
  from: {},
  to: {
    transform: 'rotate(360deg)',
  }
})

// pulsing box shadow animation
const pulse = (theme, thickness) => keyframes({
  '0%': {
    boxShadow: `0 0 0 0 ${theme.color.primary.primary2}`,
  },
  '70%': {
    boxShadow: `0 0 ${thickness}px ${thickness / 2}px ${theme.color.primary.primary2},
                inset 0 0 ${thickness * 2}px ${thickness / 2}px ${theme.color.primary.primary2}`,
  },
  '100%': {
    boxShadow: `0 0 0 0 ${theme.color.primary.primary2}`,
  }
});

export const spinnerStyles = (theme) => ({
  size: SIZE,
  thickness: THICKNESS,
  duration: DURATION,
  animation: theme.animation.fade(DURATION), // enter/leave animation
  animationName: 'fade',
  circle: {
    animation: `${spin} 3s linear infinite`,
    borderRadius: '50%',
    background: [
      theme.color.primary.primary1,
      `linear-gradient(0, ${theme.color.primary.primary1} 0%, ${theme.color.primary.primary5} 100%)`
    ],
  },
  border: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    background: 'none',
    animation: `${pulse(theme, THICKNESS)} 3s ease-in infinite`,
    opacity: 0.5,
  },
});

export default spinnerStyles;
