export const dividerStyles = (theme) => ({
  default: {
    height: '1px',
    border: 'none',
    flexShrink: '0',
    borderBottom: theme.component.border.neutral,
    margin: `${theme.spacing.sp2}px 0`,
  },
  dashed: {
    borderBottom: theme.component.border.dashed,
  },
  light: {
    borderBottom: theme.component.border.light,
  },
  large: {
    margin: `${theme.spacing.sp6}px 0`,
  }
});

export default dividerStyles;
