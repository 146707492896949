import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaterialPopover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

// TODO: make this our own ~ currently mooching off of material-ui
export class Popover extends Component {
  static displayName = 'Popover';

  static propTypes = {
    action: PropTypes.func,
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    anchorOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['left', 'center', 'right']),
      ]),
      vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['top', 'center', 'bottom'])]),
    }),
    anchorPosition: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
    }),
    anchorReference: PropTypes.oneOf(['anchorEl', 'anchorPosition', 'none']),
    children: PropTypes.node,
    classes: PropTypes.object, // alias for css
    css: PropTypes.object, // here we split from material, css is optional.
    container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    elevation: PropTypes.number,
    getContentAnchorEl: PropTypes.func,
    marginThreshold: PropTypes.number,
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onEntering: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,
    onExiting: PropTypes.func,
    open: PropTypes.bool,
    PaperProps: PropTypes.object,
    role: PropTypes.string,
    transformOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['left', 'center', 'right']),
      ]),
      vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['top', 'center', 'bottom'])]),
    }),
    TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    transitionDuration: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
      PropTypes.oneOf(['auto']),
    ]),
    TransitionProps: PropTypes.object,
  }

  static defaultProps = {
    open: false,
  }

  render() {
    const {
      type,
      classes = {},
      css: cssOverrides = {},
      ...other
    } = this.props;

    const StyledPopover = withStyles(merge({}, classes, cssOverrides))(MaterialPopover);
    return <StyledPopover {...other} />;
  }
}

export default Popover;
