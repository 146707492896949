export const tableStyles = theme => ({
  root: {
    margin: `0 ${theme.spacing.sp4}px`,
    width: `calc(100% - ${(theme.spacing.sp4 * 2)}px)`,
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  rootStriped: {
    'tr:nth-child(even) td': {
      backgroundColor: theme.color.neutral.neutral3,
    },
  },
  body: {},
  head: {
    td: {
      ...theme.typography.variants.overline,
      color: theme.color.neutral.neutral6,
    }
  },
  row: {},
  cell: {
    ...theme.typography.variants.small,
    color: theme.color.neutral.neutral6,
    padding: theme.spacing.sp4,
    borderBottom: `1px solid ${theme.color.neutral.neutral3}`
  },
});
