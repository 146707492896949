export const textFieldStyles = theme => ({
  root: {
    margin: `${theme.spacing.sp4}px 0`
  },
  iconWrapper: {
    position: 'relative'
  },
  errorIcon: {
    fontSize: 24,
    color: theme.color.result.result4,
    position: "absolute",
    right: theme.spacing.sp2,
    top: "50%",
    transform: "translateY(-50%)"
  },
  input: {
    default: {
      ...theme.typography.variants.body,
      boxSizing: "border-box",
      color: theme.color.neutral.black,
      border: `1px solid ${theme.color.neutral.neutral5}`,
      borderRadius: 4,
      padding: theme.spacing.sp2,
      outline: "none"
    },
    focused: {
      borderColor: theme.color.primary.primary5,
      boxShadow: `0 0 0 1px ${theme.color.primary.primary5}`
    },
    error: {
      borderColor: theme.color.result.result4,
      boxShadow: `0 0 0 1px ${theme.color.result.result4}`,
      paddingRight: theme.spacing.sp5
    },
    disabled: {
      color: theme.color.neutral.neutral3,
      borderColor: theme.color.neutral.neutral3
    },
    placeholder: {
      color: theme.color.neutral.neutral5,
      fontStyle: 'italic',
    }
  },
  label: {
    default: {
      ...theme.typography.variants.body,
      color: theme.color.neutral.black,
      marginBottom: theme.spacing.sp1
    },
    disabled: {},
    error: {}
  }
});
