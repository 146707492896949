import { pxToRem } from '../../styles/typography';

export const chipStyles = (theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.color.primary.primary2,
    borderRadius: theme.component.radius.r2,
    cursor: 'default',
    display: 'inline-flex',
    flexDirection: 'row',
    flexShrink: 0,
    height: theme.spacing.sp5,
    justifyContent: 'center',
    outline: 'none',
    whiteSpace: 'nowrap',
  },
  label: {
    ...theme.typography.variants.body,
    color: theme.color.primary.primary7,
    cursor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing.sp1,
    marginRight: theme.spacing.sp1,
  },
  remove: {
    '&:hover': {
      color: theme.color.primary.primary7
    },
    color: theme.color.primary.primary4,
    cursor: 'pointer',
    flexShrink: '0',
    fontSize: pxToRem(16),
    marginLeft: -theme.spacing.sp1,
    padding: theme.spacing.sp1,
    transition: 'fill 150ms ease-in',
    userSelect: 'none',
    WebkitTapHighlightColor: 'transparent'
  },
  avatar: {
    fontSize: pxToRem(14),
    color: theme.color.primary.primary7,
    WebkitTapHighlightColor: 'transparent',
    marginLeft: theme.spacing.sp1,
  },
});

export default chipStyles;
