import _, { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, keyframes } from '@emotion/core';

import { View } from '../view/view.component';

const shimmer = keyframes({
  '0%': {
    backgroundPosition: '100% 0',
  },
  '100%': {
    backgroundPosition: '-100% 0',
  },
});

const styles = {
  line: {
    height: 16,
    margin: 10,
  },
  shimmer: {
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: `${shimmer}`,
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #e4e4e4 8%, #b3b3b3 18%, #e4e4e4 33%)',
    backgroundSize: '200% 100%',
    position: 'relative',
  },
};

const cssPropTypes = PropTypes.exact({
  root: PropTypes.object,
  line: PropTypes.object,
});

export class Placeholder extends Component {
  static displayName = 'Placeholder';

  static propTypes = {
    lines: PropTypes.number,
    classes: cssPropTypes,
    css: cssPropTypes,
  };

  static defaultProps = {
    lines: 1,
  };

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      lines,
    } = this.props;

    const cssCombined = merge({}, classes, cssOverrides);

    return (
      <View css={cssCombined.root}>
        {_.range(lines).map((line, i) => (
          <View
            key={i}
            css={css(styles.line, styles.shimmer, cssCombined.line)}
            style={{width: `${50 + Math.random() * 50}%`}}
          />
        ))}
      </View>
    );
  }
}

export default Placeholder;
