import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import View from '../view/view.component';

import TIMELINE_STYLES from './timeline.styles';

const cssPropShape = PropTypes.shape({
  root: PropTypes.object,
  inner: PropTypes.object,
});

export class Timeline extends Component {
  static displayName = 'Timeline';

  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: cssPropShape,
    css: cssPropShape,
  }

  static defaultProps = {
    classes: {
      root: {},
      inner: {}
    },
    css: {
      root: {},
      inner: {}
    }
  }

  render() {
    const {
      children,
      css: cssOverrides,
      classes,
      ...other
    } = this.props

    return (
      <View css={css(TIMELINE_STYLES.timeline, classes.root, cssOverrides.root)} {...other} >
        <View css={css(TIMELINE_STYLES.timelineInnerStyles, classes.inner, cssOverrides.inner)}>
          { children }
        </View>
      </View>
    );
  }
}

export default Timeline;
