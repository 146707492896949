import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css as emotion_css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

const cssPropShape = PropTypes.shape({
  /** label styles */
  label: PropTypes.object,
  /** input styles */
  input: PropTypes.object,
  /** small radiobutton styles */
  small: PropTypes.object,
});

export class Radio extends Component {
  static displayName = 'Radio';

  static defaultProps = {
    checked: false,
    disabled: false,
    small: false,
    classes: {},
  };

  static propTypes = {
    /** id of the radio button element */
    id: PropTypes.string,
    /** name for the field, important for Form elements */
    name: PropTypes.string,
    /** The value of this radio button */
    value: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, PropTypes.string]).isRequired,

    /** Forwarded ref to the underlying native input element */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),

    /** default checked element */
    checked: PropTypes.bool,
    /** If true, the user won't be able to interact with the raio button. */
    disabled: PropTypes.bool,

    /** Variant, if true use small icons/type. */
    small: PropTypes.bool,

    /** Base styles of the radio button. */
    css: cssPropShape,
    /** Customize the styles of the radio button. */
    classes: cssPropShape,

    /** Event handler, invoked when the checked state changes. */
    onChange: PropTypes.func,
  };

  state = {
    checked: this.props.checked
  };

  handleChange = (event) => {
    this.setState({ checked: !this.state.checked})
    const { onChange } = this.props;
    onChange && onChange(event);
  };

  render() {
    const {
      children,
      small,
      checked,
      disabled,
      onBlur,
      onChange,
      value,
      name,
      id,
      inputRef,
      classes,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const labelId = id ? `${id}-label` : null;

    const nativeControl = <input
      type='radio'
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      css={emotion_css(
        theme.radio.input,
        small && theme.radio.smallInput,
        classes.input,
      )}
      onChange={this.handleChange}
      onBlur={onBlur}
      ref={inputRef}
      {...other}
    />;

    return (
      <label
        css={emotion_css(
          theme.radio.label,
          disabled && theme.radio.disabled,
          small && theme.radio.smallLabel,
          classes.label,
        )}
        htmlFor={id}
        id={labelId}
      >
        {nativeControl}
        {children}
      </label>
    );
  }
}

/**
 * @component
 */
export default Radio;
