import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaterialMenuItem from '@material-ui/core/MenuItem';

export class MenuItem extends Component {
  static displayName = 'MenuItem';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override Styles */
    css: PropTypes.object,
    component: PropTypes.node,
    selected: PropTypes.bool,
  }

  render() {
    const { classes, css, ...others } = this.props;
    return(
      <MaterialMenuItem classes={{...classes, ...css}} {...others} />
    );
  }
}

export default MenuItem;
