import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { css } from '@emotion/core';
import { merge } from 'lodash'

import defaultTheme from '../../styles/theme';

import { View } from '../view/view.component';
import { Icon } from '../icon/icon.component';
import Label from '../form-helpers/label.component';
import ErrorMessage from '../form-helpers/error-message.component';
import Helptext from '../form-helpers/help-text.component';

class DateMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;

    return (
      <MaskedInput
        ref={inputRef}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions
        placeholderChar={'\u2000'}
        {...other}
      />
    );
  }
};

class DateAutocorrectMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;

    return (
      <MaskedInput
        ref={inputRef}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions
        pipe={createAutoCorrectedDatePipe('mm/dd/yyyy')}
        placeholderChar={'\u2000'}
        {...other}
      />
    );
  }
};

class PhoneMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;

    return (
      <MaskedInput
        ref={inputRef}
        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions
        placeholderChar={'\u2000'}
        {...other}
      />
    );
  }
};

class CustomMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;

    return (
      <MaskedInput
        ref={inputRef}
        keepCharPositions
        placeholderChar={'\u2000'}
        {...other}
      />
    );
  }
};

/**
 * NativeInput w/ React.forwardRef
 * Use a fowarding ref so we can set ref to the native input and ignore
 * inputRef for this component (consistent API with above components)
 */
const NativeInput = React.forwardRef(({ inputRef, ...rest }, innerRef) => (
  <input {...rest} ref={innerRef} />
));

const masks = {
  date: DateMask,
  dateAutocorrect: DateAutocorrectMask,
  phone: PhoneMask,
  native: NativeInput,
  custom: CustomMask,
};

const cssProps = PropTypes.shape({
  root: PropTypes.object,
  errorIcon: PropTypes.object,
  input: PropTypes.shape({
    default: PropTypes.object,
    focused: PropTypes.object,
    disabled: PropTypes.object,
    error: PropTypes.object,
    placeholder: PropTypes.object,
  }),
  label: PropTypes.shape({
    default: PropTypes.object,
    error: PropTypes.object,
  })
});

export class TextField extends Component {
  static displayName = 'TextField';
  static contextTypes = { theme: PropTypes.object };

  static propTypes = {
    /** Alias for css */
    classes: cssProps,
    /** Override or extend the classes applied to the component. */
    css: cssProps,
    disabled: PropTypes.bool,
    /* helper text **/
    helptext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /* If text or HTML element then show an error message, if boolean true then just add error styles **/
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    /** Forwarded ref to the underlying native input element */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
    /* The label content. **/
    label: PropTypes.node,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    /* Defaults to an uncontrolled component, if you are managing state, provide a value or you will get a React warning */
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    disabled: false,
    error: false,
    onChange: () => { },
    mask: 'native',
    placeholder: '',
    value: undefined,
  }


  renderInput() {
    const {
      classes: classesProp,
      css: cssOverrides,
      mask,
      inputRef,
      error,
      label,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const mergedCss = merge({}, classesProp, cssOverrides);

    const classes = merge({}, theme.textField, mergedCss);

    const inputClass = css(
      classes.input.default,
      { '::placeholder': classes.input.placeholder },
      { ':focus': classes.input.focused },
      error && classes.input.error,
      { '[disabled]': classes.input.disabled }
    );

    let maskType;
    if (typeof mask === 'string') {
      maskType = mask;
    } else {
      maskType = 'custom';
      other.mask = mask;
    }
    const Component = masks[maskType];
    return <Component ref={inputRef} {...other} css={inputClass} />;
  }

  render() {
    const {
      classes: classesProp,
      css: cssOverrides,
      error,
      helptext,
      label,
    } = this.props;

    const { theme = defaultTheme } = this.context;
    const classes = merge({}, theme.textField, classesProp, cssOverrides);

    const labelClass = css(
      classes.label.default,
      error && classes.label.error,
      this.props.disabled && classes.label.disabled
    );

    return (
      <View css={css(classes.root)}>
        <Label
          css={css(labelClass)}
        >
          {label}
        </Label>
        <View css={css(classes.iconWrapper)}>
          {this.renderInput()}
          {error && <Icon css={css(classes.errorIcon)}>alert-circle</Icon>}
        </View>
        <Helptext>{helptext}</Helptext>
        {!!error && typeof error !== 'boolean' && <ErrorMessage>{error}</ErrorMessage>}
      </View>
    )
  }
}

export default TextField;
