import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';
import Divider from '../divider/divider.component';
import ListItem from '../list/list-item.component';
import Text from '../text/text.component';

export default class SearchMenuItem extends Component {
  static displayName = 'SearchMenuItem';

  static contextTypes = { theme: PropTypes.object }

  static propTypes = {
    children: PropTypes.node,
    isHighlighted: PropTypes.bool,
    item: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  }

  static defaultProps = {
    item: {},
  }

  renderContent() {
    const { theme = defaultTheme } = this.context;
    const {
      item,
      children,
    } = this.props;

    if (children) {
      return children;
    }

    return (
      <Text css={theme.search.menu.item.text}>
        {item.label}
      </Text>
    );
  }

  render() {
    const { theme = defaultTheme } = this.context;
    const {
      item,
      isFocused,
      getItemProps,
      isHighlighted,
      ...otherProps
    } = this.props;
    return ([
      <ListItem
        css={css(
          isHighlighted && theme.search.menu.item.focused,
          theme.search.menu.item.root,
        )}
        key={`${item.id}-item`}
        { ...otherProps }
      >
        { this.renderContent() }
      </ListItem>,
      <Divider css={theme.search.menu.item.divider} key={`${item.id}-divider`} />
    ]);
  }
};
