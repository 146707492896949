/** @jsx jsx */
import _ from 'lodash';
import { Component } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import defaultTheme from '../../styles/theme';

export { default as TableBody } from './table-body.component';
export { default as TableCell } from './table-cell.component';
export { default as TableHead } from './table-head.component';
export { default as TableRow } from './table-row.component';

export class Table extends Component {
  static displayName = 'Table';
  static propTypes = {
    /** Legacy alias for css */
    classes: PropTypes.object,
    /** Customize the styles of the table. */
    css: PropTypes.shape({
      /** the outer Table styles */
      root: PropTypes.object,
      /** when striped, these rules apply to the table */
      rootStriped: PropTypes.object,
      /** tbody */
      body: PropTypes.object,
      /** thead */
      head: PropTypes.object,
      /** tr */
      row: PropTypes.object,
      /** td */
      cell: PropTypes.object,
    }),
    /** If true, Every other row will be rendered darker. */
    striped: PropTypes.bool,
  }

  render() {
    const {
      children,
      classes = {},
      css: cssOverrides,
      striped = false,
      ...other
    } = this.props;
    const { theme = defaultTheme } = this.context;

    const bodyTextStyles = {
      root: {
      ...theme.typography.variants['body'],
      }
    };
    const themedClasses = _.merge({},
      bodyTextStyles,
      defaultTheme.table.root,
      striped ? defaultTheme.table.rootStriped : {},
      classes,
      cssOverrides,
    );

    return (
      <table {...other} css={css(themedClasses)}>
        {children}
      </table>
    )
  }
}

export default Table;
