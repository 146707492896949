import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledSlider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class Slider extends Component {
  static defaultProps = {
    arrows: true,
    dots: true,
    infinite: true,
  };

  static propTypes = {
    /** Enable tabbing and arrow key navigation */
    accessibility: PropTypes.bool,
    /** Adjust the slide's height automatically */
    adaptiveHeight: PropTypes.bool,
    /** Index change callback. `index => ...` */
    afterChange: PropTypes.func,
    /** Custom dots templates. Works same as customPaging */
    appendDots: PropTypes.func,
    arrows: PropTypes.bool,
    /** provide ref to another slider and sync it with current slider */
    asNavFor: PropTypes.node,
    /** Delay between each auto scroll (in milliseconds) */
    autoplaySpeed: PropTypes.number,
    autoplay: PropTypes.bool,
    /** Index change callback. `(oldIndex, newIndex) => ...` */
    beforeChange: PropTypes.func,
    /** Center current slide */
    centerMode: PropTypes.bool,
    centerPadding: PropTypes.string,
    /** CSS class for inner slider div */
    className: PropTypes.string,
    /** Custom paging templates. [Example](examples/CustomPaging.js) */
    customPaging: PropTypes.func,
    /** CSS class for dots */
    dotsClass: PropTypes.string,
    dots: PropTypes.bool,
    /** Enable scrollable via dragging on desktop */
    draggable: PropTypes.bool,
    easing: PropTypes.string,
    fade: PropTypes.bool,
    /** Go to slide on click */
    focusOnSelect: PropTypes.bool,
    /** Infinitely wrap around contents */
    infinite: PropTypes.bool,
    /** Index of first slide */
    initialSlide: PropTypes.number,
    /** Load images or render components on demand or progressively */
    lazyLoad: PropTypes.oneOf(['ondemand', 'progressive']),
    /** Edge dragged event in finite case, `direction => {...}` */
    onEdge: PropTypes.func,
    /** componentWillMount callback. `() => void` */
    onInit: PropTypes.func,
    /** Callback after slides load lazily `slidesLoaded => {...}` */
    onLazyLoad: PropTypes.func,
    /** componentDidUpdate callback. `() => void` */
    onReInit: PropTypes.func,
    /** Callback after slide changes by swiping */
    onSwipe: PropTypes.func,
    /** Prevents autoplay while hovering on dots */
    pauseOnDotsHover: PropTypes.bool,
    /** Prevents autoplay while focused on slides */
    pauseOnFocus: PropTypes.bool,
    /** Prevents autoplay while hovering on track */
    pauseOnHover: PropTypes.bool,
    /** Customize based on breakpoints (see the demo example for better understanding) */
    responsive: PropTypes.array,
    /** number of rows per slide in the slider, (enables grid mode) */
    rows: PropTypes.number,
    /** Reverses the slide order */
    rtl: PropTypes.bool,
    /** Slide container type */
    slide: PropTypes.string,
    /** number of slides to display in grid mode, this is useful with rows option */
    slidesPerRow: PropTypes.number,
    /** How many slides to scroll at once */
    slidesToScroll: PropTypes.number,
    /** How many slides to show in one frame */
    slidesToShow: PropTypes.number,
    /** Animation speed in milliseconds */
    speed: PropTypes.number,
    /** Enable drag/swipe irrespective of `slidesToScroll` */
    swipeToSlide: PropTypes.bool,
    /** Enable/disable swiping to change slides */
    swipe: PropTypes.bool,
    touchMove: PropTypes.bool,
    touchThreshold: PropTypes.number,
    /** Enable/Disable CSS Transitions */
    useCSS: PropTypes.bool,
    /** Enable/Disable CSS transforms */
    useTransform: PropTypes.bool,
    variableWidth: PropTypes.bool,
    vertical: PropTypes.bool,
  };

  render() {
    const { children, innerRef } = this.props;
    return (
      <StyledSlider {...this.props} ref={innerRef}>
        {children}
      </StyledSlider>
    );
  }
}

export default React.forwardRef((props, ref) => <Slider
  innerRef={ref} {...props}
/>);
