export const radioGroupStyles = (theme) => ({
  stacked: {
    "& > *": {
      marginBottom: `${theme.spacing.sp2}px`,
    }
  },
  inline: {
    "& > *": {
      marginRight: `${theme.spacing.sp2}px`,
      marginBottom: `${theme.spacing.sp2}px`,
    }
  },
});

export default radioGroupStyles;