import * as color from './color';
import component from './component';
import * as layout from './layout';
import { spacing } from './spacing';
import * as typography from './typography';

export default {
  color,
  component,
  layout,
  spacing,
  typography,
};
