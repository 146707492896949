// Global things.
import _, { merge } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

// Other components
import { Collapse } from '../collapse/collapse.component';
import defaultTheme from '../../styles/theme';
import Spinner from '../spinner/spinner.component';
import Text from '../text/text.component';
import View from '../view/view.component';

// Local modules
import { latestPromise } from '../../utils/component.utils';
import SearchInput from './search-input.component';
import SearchMenu from './search-menu.component';

const cssProps = PropTypes.shape({
  /** `component` wraps the outter element. */
  component: PropTypes.object,
  /** `searchInput` styles the input field */
  searchInput: PropTypes.object,
})

export default class Search extends Component {
  static displayName = 'Search';

  static contextTypes = { theme: PropTypes.object }

  static propTypes = {
    /** If `true`, focuses the input when mounted. */
    autoFocus: PropTypes.bool,
    /** Alias for css */
    classes: cssProps,
    /** Classes for the results list. */
    css: cssProps,
    /** dataProvider(term) will be a promise that resolves results. */
    dataProvider: PropTypes.func,
    /** debounce the search method with the given duration */
    debounceDuration: PropTypes.number,
    /** Controlled Mode: Pass a custom error into the component */
    error: PropTypes.oneOfType(
      [PropTypes.object, PropTypes.instanceOf(Error)]
    ),
    /** Optional custom component for renderingErrors, passed the term by default. */
    errorComponent: PropTypes.func,
    /** Optional custom error message */
    errorMessage: PropTypes.string,
    /** Optionally Override the default itemToString method */
    itemToString: PropTypes.func,
    /** Controlled Mode: Force this component into open state. */
    isOpen: PropTypes.bool,
    /** Optionally override the icon for the search field */
    icon: PropTypes.bool,
    /** Optionally override the icon for the search field's clear button */
    clearIcon: PropTypes.bool,
    /** Controlled Mode: Force this component into loading state. */
    loading: PropTypes.bool,
    /** Should this component cache results from `dataProvider`? (Default true) */
    memozie: PropTypes.bool,
    /** callback to fire when the selection changed */
    onSelectionChanged: PropTypes.func,
    /** callback to fire when any Downshift state changes */
    onStateChange: PropTypes.func,
    /** callback to fire when user indicates that they want to search, regardless of selection */
    onSubmit: PropTypes.func,
    /** Override the onSelect method, returs the new selection, should follow results shape. */
    onSelect: PropTypes.func,
    /** callback to fire as user types */
    onQueryChange: PropTypes.func,
    /** Placeholder for the input field, default is "" */
    placeholder: PropTypes.string,
    /** Pass in a custom renderer for rendering children, see API below */
    children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
    /** Pass in a custom renderer for rendering loader */
    renderLoader: PropTypes.func,
    /** Pass in a custom renderer for rendering empty view */
    renderEmpty: PropTypes.func,
    /** Controlled Mode: Control the results */
    results: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })),
  }

  static defaultProps = {
    autoFocus: false,
    classes: {},
    css: {},
    dataProvider: () => Promise.resolve({}),
    debounceDuration: 500,
    error: null,
    memozie: true,
    onSelectionChanged: () => {},
    onSelect: null,
    onSubmit: () => {},
    onQueryChange: () => {},
    placeholder: '',
    isOpen: undefined,
    itemToString: (item) => {
      if (typeof item === 'string') { return item }
      if ( item && item.label ) { return item.label }
      return '';
    },
    loading: undefined,
    results: undefined,
    icon: 'search',
    clearIcon: 'close',
  }

  static getDerivedStateFromProps(props, state) {
    const updatedState = {};
    const controlledFields = ['error', 'loading', 'results'];

    controlledFields.forEach(field => {
      if (
        props.hasOwnProperty(field) &&
        props[field] !== undefined &&
        props[field] !== state[field]
      ) {
        updatedState[field] = props[field];
      }
    });

    return updatedState;
  }

  constructor(props) {
    super(props);
    /**
      * latestPromise will ensure that if many calls to
      * dataProvider happen quickly, the latest one will
      * resolve, ensuring that the last promise created (before any resolve)
      * will be the one that results.
      */
    const { dataProvider, debounceDuration } = props;
    this.latestDataProviderResult = latestPromise(dataProvider);
    this.debouncedPerformSearch = _.debounce(this.performSearch, debounceDuration);
    /**
      * Memoize the results.
      * This will greatly increase the speed of repeat results.
      */
    this.memoized = {};
    this.state = {
      currentQuery: '',
      error: props.error ? props.error : null,
      /** @prop { term: {String}, results: [ { label: {String}, ...rest } ]} - an array of any results. */
      results: props.results ? props.results : [],
      /** the selected element, if any. */
      selected: null,
      /** loading state tracker */
      loading: !!props.loading,
      /** empty results tracker */
    };
  }

  componentWillUnmount() {
    this.debouncedPerformSearch.cancel();
  }

  clearSelection = () => {
    this.props.onSelectionChanged(null);
    this.setState({
      selected: null,
    });
  }

  queryDidChange = (term) => {
    const {
      currentQuery,
      selected,
    } = this.state;
    const {
      debounceDuration,
      itemToString,
      loading,
      memozie,
      onQueryChange,
      results,
    } = this.props;

    onQueryChange(term);
    const isLoadingControlled = loading !== undefined;
    const areResultsControlled = Array.isArray(results);
    // change detection.
    if(currentQuery === term) {
      return;
    }

    this.setState({
      currentQuery: term,
      // if loading is controlled, set it to the prop,
      // otherwise, set to true iff term is different than selected's label
      loading: isLoadingControlled ? loading : (term !== itemToString(selected)),
    }, () => {
      if(debounceDuration > 0) {
        this.debouncedPerformSearch();
      } else {
        this.performSearch();
      }
    });

    if (!areResultsControlled && memozie && this.memoized[term]) {
      this.setState({
        results: areResultsControlled ? results : this.memoized[term],
        loading: isLoadingControlled ? loading : false,
      });
      return;
    }

  }

  performSearch = () => {
    const { currentQuery } = this.state;
    const {
      memozie,
      loading,
      results
    } = this.props;
    const isLoadingControlled = loading !== undefined;
    const areResultsControlled = Array.isArray(results);

    this.latestDataProviderResult(currentQuery)
      .then(({term, results : response }) => {
        if(!this.searchRoot) {
          // No-op.
          // Component was unmounted before request completed.
          return;
        }
        if (memozie) {
          this.memoized[term] = response;
        }
        this.setState({
          loading: isLoadingControlled ? loading : false,
          // Need to persist error state if it's coming from parent
          error: this.props.error ? this.props.error : null,
          results: areResultsControlled ? results : response,
        });
      })
      .catch(error => {
        if(!this.searchRoot) {
          // No-op.
          // Component was unmounted before request completed.
          return;
        }
        this.setState({
          loading: isLoadingControlled ? loading : false,
          error,
          results: areResultsControlled ? results : [],
        });
      });
  }

  stateReducer = (state, changes) => {
    const {
      itemToString,
      onSelect,
      onSelectionChanged,
      onStateChange,
   } = this.props;

    if (onStateChange) {
      onStateChange(state, changes);
    }

    // Modify the downshift state.
    switch(changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        if (changes.hasOwnProperty('inputValue')) {
          if (typeof state.selectedItem === 'object') {
            // meaning, we currently have a selected object.
            // we're about to change the query while an object is selected.
            this.setState({ selected: null });
            onSelectionChanged(null);
          }
          return {
            ...changes,
            selectedItem: changes.inputValue,
          }
        }
        return changes;
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.keyDownEnter:
        let newSelection = changes.selectedItem;
        if (onSelect) {
          // since we only want to call onSelect once for each time
          // a selection is made, it must be called here.
          newSelection = onSelect(changes.selectedItem);
        }
        this.setState({ selected: newSelection });
        // Should we call `onSelectionChanged` with the results of onSelect,
        // or with what it would have been if onSelect weren't called?
        onSelectionChanged(newSelection);
        return {
          ...changes,
          selectedItem: newSelection,
          inputValue: itemToString(newSelection),
        }
      default:
        return changes;
    }
  }

  onSubmit = () => {
    const {
      currentQuery,
      selected,
    } = this.state;
    const { onSubmit } = this.props;
    onSubmit(selected ? selected : currentQuery);
  }

  onFocus = (inputValue, callback) => () => {
    if(inputValue.length) {
      callback();
    }
  }

  renderInput = (inputProps) => {
    const { searchInput, icon, clearIcon } = this.props;

    if (searchInput) {
      return React.cloneElement(searchInput, inputProps);
    }

    return <SearchInput icon={icon} clearIcon={clearIcon} {...inputProps} />;
  }

  renderLoader() {
    const { theme = defaultTheme } = this.context;
    return (
      <View css={theme.search.loading}>
        <Spinner show={true} />
      </View>
    )
  }

  renderEmptyView() {
    const { currentQuery } = this.state;
    const { theme = defaultTheme } = this.context;

    return (
      <View css={theme.search.emptyView}>
        <Text>No results for "{currentQuery}"</Text>
      </View>
    )
  }

  renderError(inputValue) {
    const { error } = this.state;
    const { errorComponent, errorMessage } = this.props;
    const { theme = defaultTheme } = this.context;

    if (error) {
      if(errorComponent) {
        return errorComponent(inputValue);
      }

      return (
        <View css={{ paddingTop: theme.spacing.sp1 }}>
          <Text error>
            { errorMessage ? errorMessage : 'An error Occurred, please try again.'}
          </Text>
        </View>
      );
    }
  }

  renderChildren({ highlightedIndex, isOpen, getItemProps }) {
    const {
      currentQuery,
      error,
      loading,
      results: stateResults,
      selected,
    } = this.state;
    const {
      children,
      results: propResults,
    } = this.props;
    const { theme = defaultTheme } = this.context;

    const areResultsControlled = Array.isArray(propResults);
    const results = areResultsControlled ? propResults : stateResults;
    const pack = {
      currentQuery,
      error,
      getItemProps,
      highlightedIndex,
      isOpen,
      loading,
      results,
      selected,
    };

    const isEmpty = !loading && !!currentQuery && currentQuery !== '' && results && results.length === 0;

    // If children is not an element, assume it's a render-prop.
    if (children && !React.isValidElement(children) && typeof children === 'function') {
      return children({ ...pack, error, isEmpty });
    }

    if (!isOpen || error) {
      return null;
    }

    if (loading && this.props.renderLoader) {
      return this.props.renderLoader(pack);
    }

    if (isEmpty && this.props.renderEmpty) {
      return this.props.renderEmpty(pack);
    }

    const renderMenu = () => {
      if (!!children) {
        // Clone the children with props, that way we can apply props to the child
        return React.cloneElement(children, pack);
      }
      return <SearchMenu {...pack} />;
    };

    return (
      <View css={theme.search.menu.root}>
        { loading && this.renderLoader() }
        { isEmpty && this.renderEmptyView() }
        { (!loading && !isEmpty) && renderMenu() }
      </View>
    );
  }

  render() {
    const {
      autoFocus,
      classes,
      css: cssOverrides = {},
      itemToString,
      placeholder,
      isOpen,
      ...rest
    } = this.props;
    const {
      error,
      loading,
      selected,
    } = this.state;

    const isOpenControlled = isOpen !== undefined;
    const safeProps = _.omit(rest, Object.keys(Search.propTypes)); // eslint-disable-line

    const combinedCss = merge({}, classes, cssOverrides);

    return (
      <Downshift
        onInputValueChange={this.queryDidChange}
        itemToString={itemToString}
        stateReducer={this.stateReducer}
        ref={(el) => { this.searchRoot = el; }}
        isOpen={isOpenControlled ? isOpen : undefined}
        {...safeProps}
      >
        {({
          getInputProps,
          getRootProps,
          getItemProps,
          clearSelection,
          clearItems,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
        }) => (
          <View
            css={combinedCss.component}
            {...getRootProps({ refKey: 'innerRef' })}
          >
            {this.renderInput(getInputProps({
              placeholder,
              expanded: isOpen || loading,
              onClear: clearSelection,
              classes: combinedCss.searchInput,
              selected: selected,
              autoFocus: autoFocus,
              error: error,
              onSubmit: this.onSubmit,
              onFocus: this.onFocus(inputValue, openMenu),
            }))}
            {this.renderError(inputValue)}
            <Collapse
              css={{ position: 'relative' }}
              in={(isOpen || loading)}
              timeout="auto"
            >
              {this.renderChildren({ highlightedIndex, isOpen, getItemProps })}
            </Collapse>
          </View>
        )}
      </Downshift>
    )
  }
}
