import { merge } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import View from '../view/view.component';

import defaultTheme from '../../styles/theme';

export class CardContent extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'CardContent';

  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    css: PropTypes.object,
  };

  static defaultProps = {
    classes: {},
    css: {},
  };

  render() {
    const { theme = defaultTheme } = this.context;

    const {
      children,
      classes = {},
      css: cssOverrides = {},
      ...other
    } = this.props;

    const combinedCss = merge({}, classes, cssOverrides);

    return (
      <View
        css={
          css(
            theme.card.content,
            combinedCss,
          )
        }
        {...other}
      >
        {children}
      </View>
    );
  }
}

export default CardContent;
