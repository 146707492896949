import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaterialMenu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

export { default as  MenuItem } from './menu-item.component';

// TODO: make this our own ~ currently mooching off of material-ui
export class Menu extends Component {
  static displayName = 'Menu';

  static propTypes = {
    anchorEl: PropTypes.node,
    children: PropTypes.node,
    classes: PropTypes.object,
    css: PropTypes.object,
    disableAutoFocusItem: PropTypes.bool,
    MenuListProps: PropTypes.object,
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    open: PropTypes.bool,
    PopoverClasses: PropTypes.object,
    transitionDuration: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        enter: PropTypes.number,
        exit: PropTypes.number
      }),
      PropTypes.oneOf(['auto',]),
    ])
  }

  static defaultProps = {
    open: false,
    classes: {},
    css: {},
  }

  render() {
    const {
      type,
      classes = {},
      css: cssOverrides = {},
      ...other
    } = this.props;

    const StyledMenu = withStyles(classes, cssOverrides)(MaterialMenu);
    return <StyledMenu {...other} />;
  }
}

export default Menu;
