(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@emotion/core"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@emotion/core", "react", "react-dom"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@emotion/core"), require("react"), require("react-dom")) : factory(root["@emotion/core"], root["react"], root["react-dom"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__emotion_core__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__) {
return 