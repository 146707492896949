const xs = 480;
const sm = 768;
const md = 980;
const lg = 1210;
const xl = 1400;
const min = width => `(min-width: ${(width - 1)}px)`;
const max = width => `(max-width: ${(width)}px)`;
// export private vars just for testing.
export const _testVars = {
  min,
  max,
}

export const breakpoints = {
  xs,
  sm,
  md,
  lg,
  xl,
}

/**
@param {Object} options - e.x { type: 'screen', minWidth: sm, maxWidth: lg }
@return {String} - css rules, like '@media only screen and (min-width: 100px)'
*/
export const mediaQuery = ({ type, minWidth, maxWidth }) => {
  const output = [];

  if (type) {
    output.push(`@media only ${type}`);
  }

  if (minWidth) {
    output.push(min(minWidth));
  }

  if (maxWidth) {
    output.push(max(maxWidth));
  }
  return output.join(' and ')
};

/** @constant
  @type {Object}
  A hash of useful media queries.
*/
export const screens = {
  mobile: mediaQuery({ type: 'screen', maxWidth: md }),
  tablet: mediaQuery({ type: 'screen', minWidth: sm, maxWidth: md }),
  desktop: mediaQuery({ type: 'screen', minWidth: md }),

  xs: mediaQuery({ type: 'screen' }),
  sm: mediaQuery({ type: 'screen', minWidth: sm }),
  md: mediaQuery({ type: 'screen', minWidth: md }),
  lg: mediaQuery({ type: 'screen', minWidth: lg }),
  xl: mediaQuery({ type: 'screen', minWidth: xl }),
};

// deprecated, legacy support phasing out.
export const Breakpoints = breakpoints;
export const MediaQuery = mediaQuery;
export const Screens = screens;
