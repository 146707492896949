import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from '@emotion/core';
import { CSSTransition } from 'react-transition-group';

import { View } from '../view/view.component';

import defaultTheme from '../../styles/theme';

export class Spinner extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Spinner';

  static propTypes = {
    /** Duration in ms of enter/leave animation. */
    duration: PropTypes.number,
    /** Name of enter/leave animation. */
    animationName: PropTypes.string,
    /** Show the spinner (defaults to true). */
    show: PropTypes.bool,
    /** The size of the circle. */
    size: PropTypes.number,
    /** The thickness of the circle. */
    thickness: PropTypes.number,
  };

  static defaultProps = {
    show: true,
  };

  render() {
    const { theme = defaultTheme } = this.context;

    const { classes = {}, css: cssOverrides = {} } = this.props;

    const mergedCss = merge({}, classes, cssOverrides);
    const {
      classes: _classes,
      css: _cssOverrides,
      duration = mergedCss.duration || theme.spinner.duration,
      animationName = mergedCss.animationName || theme.spinner.animationName,
      show,
      size = mergedCss.size || theme.spinner.size,
      thickness = mergedCss.thickness || theme.spinner.thickness,
      ...other
    } = this.props;



    return (
      <CSSTransition
        in={show}
        timeout={duration}
        classNames={animationName}
        unmountOnExit
      >
        <View
          css={css(
            theme.spinner.animation,
            theme.spinner.circle,
            mergedCss.animation,
            mergedCss.circle,
            { height: size, width: size, }
          )}
          {...other}
        >
          <View
            css={css(
              {
                '&:after': {
                  content: '""',
                  width: size - thickness,
                  height: size - thickness,
                  background: 'white',
                  position: 'absolute',
                  top: thickness/2,
                  left: thickness/2,
                  borderRadius: '50%',
                }
              },
              theme.spinner.animation,
              theme.spinner.circle,
              mergedCss.animation,
              mergedCss.circle,
              { height: size, width: size, },
            )}
          />
          <View css={css(theme.spinner.border, mergedCss.border)} />
        </View>
      </CSSTransition>
    )
  }
}

export default Spinner;
