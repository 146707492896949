import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaterialSnackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

// TODO: make this our own ~ currently mooching off of material-ui
export class Snackbar extends Component {
  static displayName = 'Snackbar';

  static propTypes = {
    action: PropTypes.node,
    anchorOrigin: PropTypes.shape({
      horizontal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['left', 'center', 'right']),
      ]),
      vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['top', 'center', 'bottom'])]),
    }),
    autoHideDuration: PropTypes.number,
    children: PropTypes.element,
    classes: PropTypes.object, // here we split from material, classes is optional.
    className: PropTypes.string,
    css: PropTypes.string,
    ContentProps: PropTypes.object,
    disableWindowBlurListener: PropTypes.bool,
    key: PropTypes.any,
    message: PropTypes.node,
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onEntering: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,
    onExiting: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    open: PropTypes.bool,
    resumeHideDuration: PropTypes.number,
    TransitionComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
      transitionDuration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
      ]),
    TransitionProps: PropTypes.object,
  }

  render() {
    const { type, classes = {}, css: cssOverrides = {}, ...other } = this.props;
    const combinedCss = merge({}, classes, cssOverrides);
    const StyledSnackbar = withStyles(combinedCss)(MaterialSnackbar);
    return <StyledSnackbar {...other} />;
  }
}

export default Snackbar;
