import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import View from '../view/view.component';

const styles = {
  default: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    fontSize: 12,
  },
};

/**
 * The only true list item.
 */
export class ListItem extends Component {
  static displayName = 'ListItem';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /** If true, the `ListItem` will be a button. */
    button: PropTypes.bool,
  };

  static defaultProps = {
    button: false,
    classes: {},
    css: {},
  };

  render() {
    const {
      classes,
      css: cssOverrides,
      button,
      ...other
    } = this.props;

    return (
      <View css={css(styles.default, classes, cssOverrides)} {...other}>
        {this.props.children}
      </View>
    )
  }
}

/**
 * @component
 */
export default ListItem;
