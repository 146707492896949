import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

import View from '../view/view.component';

export class ModalTitle extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'ModalTitle';

  static propTypes = {
    children: PropTypes.node,
    /** Alias for css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
  };

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      children,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    return (
      <View
        css={css(
          theme.modal.title,
          classes,
          cssOverrides
        )}
        {...other}
      >
        {children}
      </View>
    );
  }
}

export default ModalTitle;
