export const checkboxGroupStyles = (theme) => ({
  wrapper: {
    flexWrap: 'wrap',
  },
  stacked: {
    "& > *": {
      marginBottom: `${theme.spacing.sp3}px`,
    }
  },
  inline: {
    "& > *": {
      marginRight: `${theme.spacing.sp3}px`,
      marginBottom: `${theme.spacing.sp3}px`,
    }
  },
});

export default checkboxGroupStyles;
