import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Text from '../text/text.component';

import defaultTheme from '../../styles/theme';

/**
 * The only true Error Message.
 */
export class ErrorMessage extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'ErrorMessage';

  static propTypes = {
    /** Error message text or HTML element */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    /** alias for css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
  };

  static defaultProps = {
    children: '',
    classes: {},
    css: {},
  }

  render() {
    const {
      children,
      classes,
      css: cssOverrides,
      ...other
    } = this.props;
    
    if (!children) {
      return null;
    }
    const { theme = defaultTheme } = this.context;

    const cssClasses = css(
      theme.formHelper.errorMessage,
      classes,
      cssOverrides,
    );

    if(typeof children === 'string') {
      return (
        <Text
          color="error"
          type="tiny"
          my={0}
          css={cssClasses}
          {...other}
        >
          {children}
        </Text>
      );
    } else {
      return React.Children.map(children, child => {
        return React.cloneElement(child, {
          css: cssClasses,
        });
      });
    }
  }
}

export default ErrorMessage;
