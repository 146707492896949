// Global things.
import { merge } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import keycode from 'keycode';

// Other components
import defaultTheme from '../../styles/theme';
import Icon from '../icon/icon.component';
import TextInput from '../text-input/text-input.component';
import View from '../view/view.component';

const cssProps = PropTypes.shape({
  /** Style the wrapper, aka root container */
  wrapper: PropTypes.object,
  /** Style the icon */
  icon: PropTypes.object,
  /** Style the chip container */
  container: PropTypes.object,
  /** Style the input */
  input: PropTypes.object,
});

export default class SearchInput extends Component {
  static displayName = 'SearchInput';
  static contextTypes = { theme: PropTypes.object }

  static propTypes = {
    autofocus: PropTypes.bool,
    error: PropTypes.object,
    expanded: PropTypes.bool,
    inputRef: PropTypes.func,
    icon: PropTypes.string,
    clearIcon: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })),
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    classes: cssProps,
    css: cssProps,
  }

  static defaultProps = {
    onChange: () => { },
    onClear: () => { },
    onKeyDown: () => { },
    onSubmit: () => { },
    expanded: false,
    classes: {},
    css: {},
    value: '',
    placeholder: '',
    icon: 'search',
    clearIcon: 'close',
  }

  onIconClicked = () => {
    const { value, onClear, selected } = this.props;
    if ((value && value.length) || (selected && selected.length)) {
      onClear();
    }
  }

  onKeyDown = (e) => {
    const { onKeyDown, onSubmit } = this.props;
    if (keycode.isEventKey(e, 'enter')) {
      onSubmit();
    }
    onKeyDown(e);
  }

  render() {
    const {
      autoFocus,
      classes = {},
      css: cssOverrides = {},
      expanded,
      inputRef,
      onChange,
      value,
      placeholder,
      onBlur,
      onKeyDown,
      onKeyUp,
      onClear,
      error,
      icon,
      clearIcon,
      ...rest
    } = this.props;

    const events = {
      onBlur,
      onChange,
      onKeyDown,
    }

    const { theme = defaultTheme } = this.context;

    const activeQuery = value && value !== '';

    const combinedCss = merge({}, classes, cssOverrides);

    return (
      <View
        css={css(combinedCss.wrapper, theme.search.input.root)}
        {...rest}
      >
        <View
          css={css(
            theme.search.input.wrapper,
            expanded && theme.search.input.expanded,
            combinedCss.container,
          )}
          row
        >
          <TextInput
            ref={inputRef}
            css={css(
              theme.search.input.field,
              combinedCss.input,
            )}
            {...events}
            autoFocus={autoFocus}
            onKeyUp={this.onKeyUp}
            placeholder={placeholder}
            type="text"
            value={value}
            error={error ? error : undefined}
          />
          <Icon
            color={theme.color.primary.primary7}
            css={css(theme.search.searchIcon, combinedCss.icon)}
            onClick={this.onIconClicked}
          >
            {activeQuery ? clearIcon : icon}
          </Icon>
        </View>
      </View>
    );
  }
}
