import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { spacing } from './spacing';
import * as animation from './animation';
import * as color from './color';
import * as layout from './layout';
import * as transition from './transition';
import * as typography from './typography';
import component from './component';

import { buttonStyles } from '../components/button/button.styles';
import { cardStyles } from '../components/card/card.styles';
import { formHelperStyles } from '../components/form-helpers/form-helpers.styles';
import { checkboxStyles } from '../components/checkbox/checkbox.styles';
import { checkboxGroupStyles } from '../components/checkbox-group/checkbox-group.styles';
import { radioStyles } from '../components/radio/radio.styles';
import { radioGroupStyles } from '../components/radio-group/radio-group.styles';
import { chipStyles } from '../components/chip/chip.styles';
import { collapseStyles } from '../components/collapse/collapse.styles';
import { dividerStyles } from '../components/divider/divider.styles';
import { gridStyles } from '../components/grid/grid.styles';
import { modalStyles } from '../components/modal/modal.styles';
import { navigationStyles } from '../components/navigation/navigation.styles';
import { notificationStyles } from '../components/notification/notification.styles';
import { panelStyles } from '../components/panel/panel.styles';
import { progressStyles } from '../components/progress/progress.styles';
import { searchStyles } from '../components/search/search.styles';
import { selectNativeStyles } from '../components/select-native/select-native.styles';
import { sliderStyles } from '../components/slider/slider.styles';
import { spinnerStyles } from '../components/spinner/spinner.styles';
import { tableStyles } from '../components/table/table.styles';
import { textFieldStyles } from '../components/text-field/text-field.styles';
import { textInputStyles } from '../components/text-input/text-input.styles';
import { textStyles } from '../components/text/text.styles';
import { viewStyles } from '../components/view/view.styles';

// base properties of a theme
const baseProps = {
  animation,
  color,
  component,
  layout,
  spacing,
  typography,
  transition,
};

// compute base rules that underly theme and fallback to defaults
const computeThemeBase = (themeObj = {}) => {
  return Immutable.merge(
    baseProps,
    _.pick(themeObj, Object.keys(baseProps)),
    { deep: true },
  );
}

export const computeThemeComponents = (base) => ({
  button: buttonStyles(base),
  card: cardStyles(base),
  formHelper: formHelperStyles(base),
  checkbox: checkboxStyles(base),
  checkboxGroup: checkboxGroupStyles(base),
  radio: radioStyles(base),
  radioGroup: radioGroupStyles(base),
  chip: chipStyles(base),
  collapse: collapseStyles(base),
  divider: dividerStyles(base),
  grid: gridStyles(base),
  modal: modalStyles(base),
  navigation: navigationStyles(base),
  notification: notificationStyles(base),
  panel: panelStyles(base),
  progress: progressStyles(base),
  search: searchStyles(base),
  select: selectNativeStyles(base),
  slider: sliderStyles(base),
  spinner: spinnerStyles(base),
  table: tableStyles(base),
  text: textStyles(base),
  textField: textFieldStyles(base),
  textInput: textInputStyles(base),
  view: viewStyles(base),
});

// build the theme from an object
// will deep merge passed object with defaults
// allows base properties like spacing to be overridden
export const buildTheme = (themeObj = {}) => {
  // set base rules for the theme
  // e.g. override if { color: { ..someStyles } } is explicitly set in themeObj
  const base = computeThemeBase(themeObj);
  // compute rules for the components based on the new base
  // e.g. this will apply the new theme primary color to a button background
  const components = computeThemeComponents(base);

  return Immutable.merge(
    { ...base, ...components },
    // apply direct overriding styles to component themes
    // e.g. if { card: { ..someStyles } } is explicitly set in themeObj
    _.omit(themeObj, Object.keys(baseProps)),
    { deep: true },
  );
}

// default theme object
export const defaultTheme = buildTheme();

export default defaultTheme;
