import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import defaultTheme from '../../styles/theme';

const cssPropShape = PropTypes.shape({
  /** label styles */
  label: PropTypes.object,
  /** input styles */
  input: PropTypes.object,
  /** small checkbox styles */
  small: PropTypes.object,
});

export class Checkbox extends Component {
  static displayName = 'Checkbox';

  static defaultProps = {
    checked: false,
    disabled: false,
    small: false,
  };

  static propTypes = {
    /** id of the checkbox element */
    id: PropTypes.string.isRequired,
    /** Name of the form control. Submitted with the form as part of a name/value pair. */
    name: PropTypes.string,
    /** This is the value given to the data submitted with the checkbox's name. */
    value: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, PropTypes.string]),

    /** Forwarded ref to the underlying native input element */
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),

    /** default checked element */
    checked: PropTypes.bool,
    /** If true, the user won't be able to interact with the checkbox. */
    disabled: PropTypes.bool,

    /** Variant, if true use small icons/type. */
    small: PropTypes.bool,

    /** Customize the styles of the checkbox. */
    css: cssPropShape,

    /** Event handler, invoked when the checked state changes. */
    onChange: PropTypes.func,
    /** Event handler, invoked when the checboxes are blurred. */
    onBlur: PropTypes.func,
  };

  state = {
    checked: this.props.checked
  };

  handleChange = (event) => {
    this.setState({ checked: !this.state.checked})
    const { onChange } = this.props;
    onChange && onChange(event);
  };

  render() {
    const {
      children,
      id,
      name,
      value,
      checked,
      disabled,
      small,
      classes = {},
      onChange,
      onBlur,
      inputRef,
      ...other
    } = this.props;

    const { theme = defaultTheme } = this.context;

    const labelId = id ? `${id}-label` : null;

    const nativeControl = <input
      type='checkbox'
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      css={css(
        theme.checkbox.input,
        small && theme.checkbox.smallInput,
        classes.input,
      )}
      onChange={this.handleChange}
      onBlur={onBlur}
      ref={inputRef}
      {...other}
    />;

    return (
      <label
        css={css(
          theme.checkbox.label,
          disabled && theme.checkbox.disabled,
          small && theme.checkbox.smallLabel,
          classes.label,
        )}
        htmlFor={id}
        id={labelId}
      >
        {nativeControl}
        {children}
      </label>
    );
  }
}

/**
 * @component
 */
export default Checkbox;
