import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component } from 'react';
import { css } from '@emotion/core';
import {
  flexes,
  getFlex,
  getSpacing,
  margins,
  paddings,
} from '../../utils/component.utils';

import defaultTheme from '../../styles/theme';

/**
 * The only true divider.
 */
export class Divider extends Component {
  static contextTypes = { theme: PropTypes.object };

  static displayName = 'Divider';

  static propTypes = {
    /** Alias for CSS */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    large: PropTypes.bool,
    dashed: PropTypes.bool,
    light: PropTypes.bool,
  }

  static defaultProps = {
    classes: {},
    css: {},
    large: false,
    dashed: false,
    light: false,
  };

  render() {
    const {
      classes,
      css: cssOverrides,
      dashed,
      large,
      light,
      type,
      ...other
    } = this.props;

    const remaining = _.omit(other, [
      ...Object.keys(flexes),
      ...Object.keys(margins),
      ...Object.keys(paddings),
    ]);

    const { theme = defaultTheme } = this.context;

    const flex = getFlex(this.props);
    const spacing = getSpacing(this.props, theme);

    return (
      <hr
        css={css(
          theme.divider.default,
          spacing,
          flex,
          classes,
          cssOverrides,
          dashed && theme.divider.dashed,
          light && theme.divider.light,
          large && theme.divider.large,
        )}
        {...remaining}
      />
    )
  }
}

/**
 * @component
 */
export default Divider;
