import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from '@emotion/core';

import { View } from '../view/view.component';

import defaultTheme from '../../styles/theme';

export class Progress extends Component { // eslint-disable-line
  static displayName = 'Progress';

  static propTypes = {
    /** alias of css */
    classes: PropTypes.object,
    /** Override or extend the styles applied to the component. */
    css: PropTypes.object,
    /* Control the percent fill (0-100) **/
    completed: PropTypes.number,
    /* If true, will progressively update on a random interval **/
    indeterminate: PropTypes.bool,
    /* If indeterminate, can set the estimated duration for the loading time in ms **/
    minDuration: PropTypes.number,
  };

  static defaultProps = {
    minDuration: 5000,
  };

  state = {
    completed: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if(props.completed !== undefined) {
      return {
        completed: props.completed
      };
    }

    return null;
  }

  componentDidMount() {
    const { indeterminate, minDuration } = this.props;
    if (indeterminate) {
      this.timer = setInterval(this.progress, minDuration / 10);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  progress = () => {
    const { completed } = this.state;
    const diff = Math.min(Math.random() * 10, (100 - completed)/2);
    this.setState({ completed: Math.floor(Math.min(completed + diff, 100)) });
  };

  render() {
    const {
      classes = {},
      css: cssOverrides = {},
      indeterminate,
      minDuration,
      ...other
    } = this.props;
    const { completed } = this.state;

    const { theme = defaultTheme } = this.context;

    const combinedCss = merge({}, classes, cssOverrides);
    return (
      <View
        css={css(theme.progress.root, combinedCss.root)}
        {...other}
      >
        <View
          css={css(theme.progress.bar, combinedCss.bar)}
          style={{width: `${completed}%`}}
        />
      </View>
    );
  }
}

export default Progress;
